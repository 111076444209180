export class FormikUtils {
  static nameAccessor(values, name) {
    if (!values) return;

    let nameBlocks = name.split('.');
    let targetValue = values[nameBlocks[0]];

    for (let nameBlockIndex in nameBlocks) {
      if (nameBlockIndex > 0) {
        try {
          targetValue = targetValue[nameBlocks[nameBlockIndex]];
        } catch {
          targetValue = undefined;
        }
      }
    }

    return targetValue;
  }
}
