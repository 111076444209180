import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '_redux/store';

export const portfolioEditSlice = createSlice({
  name: 'portfolioEdit',
  initialState: {
    isEditing: false,
  },
  reducers: {
    setEditing: (state, action: PayloadAction<boolean>) => {
      state.isEditing = action.payload;
    },
  },
});

export const portfolioEditActions = portfolioEditSlice.actions;
export const portfolioEdit = portfolioEditSlice.reducer;

export const selectPortfolioEditing = (state: RootState) => state.portfolioEdit.isEditing;
