import { useState } from 'react';

const defaultOptions = {
  attemptsInterval: 5 * 1000,
  maxAttempts: 5,
};

const initialState = {
  attemptCount: 0,
  remainingTime: 0,
  intervalId: null,
};

export const useRegisterConfirmTimer = (options) => {
  const { attemptsInterval, maxAttempts } = { ...defaultOptions, ...options };

  const [state, setState] = useState(initialState);

  const { intervalId, attemptCount, remainingTime } = state;

  /** Возращает promise resolve, если остались попытки, и таймер не на кулдауне */
  const requestAttempt = () => {
    if (attemptCount < maxAttempts && remainingTime === 0) {
      setState((prev) => ({ ...prev, remainingTime: attemptsInterval }));

      const intervalId = setInterval(() => {
        setState((prev) => {
          const { remainingTime } = prev;
          const nextTickRemainingTime = remainingTime - 1000;

          if (nextTickRemainingTime <= 0) {
            clearInterval(prev.intervalId);
            return { ...prev, remainingTime: 0, attemptCount: prev.attemptCount + 1 };
          } else {
            return { ...prev, remainingTime: nextTickRemainingTime };
          }
        });
      }, 1000);

      setState((prev) => ({ ...prev, intervalId }));

      return Promise.resolve();
    }

    return Promise.reject();
  };

  const resetConfirmTimer = () => {
    clearInterval(intervalId);
    setState(initialState);
  };

  return {
    attemptCount,
    remainingTime,
    requestAttempt,
    resetConfirmTimer,
  };
};
