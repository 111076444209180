import React from 'react';
import { useNavigate } from 'react-router-dom';

const QrComponent = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    setTimeout(() => {
      navigate('/');
    }, 2000);
    // eslint-disable-next-line
  }, []);

  return <div />;
};

export default QrComponent;
