import { Property } from 'csstype';

export class ClassName<VariantType = string, ColorType = string, SizeType = string> {
  private readonly variant: VariantType;
  private readonly color: ColorType;
  private readonly size: SizeType;
  private className: string = '';

  constructor(variant?: VariantType, color?: ColorType, size?: SizeType) {
    this.variant = variant;
    this.color = color;
    this.size = size;
  }

  add(className: string) {
    this.className = `${this.className} ${className}`;
    return this;
  }

  addVariant(options: {
    variant: VariantType;
    className: string;
    colors?: { color: ColorType; className: string }[];
    sizes?: { size: SizeType; className: string }[];
  }) {
    const { variant, className, colors = [], sizes = [] } = options;

    if (this.variant === variant) {
      this.add(className);

      for (let color of colors) {
        if (this.color === color.color) {
          this.add(color.className);
        }
      }

      for (let size of sizes) {
        if (this.size === size.size) {
          this.add(size.className);
        }
      }
    }

    return this;
  }

  toString() {
    return this.className;
  }
}
