import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '_redux/store';

type State = {
  taks_id: number | null;
  needCheckTaskComplete: boolean;
};

export const TASK_ID_KEY = 'TASK_ID';

const taskId = sessionStorage.getItem(TASK_ID_KEY);

const initialState: State = {
  taks_id: taskId ? Number(taskId) : null,
  needCheckTaskComplete: false,
};

export const drugoeDeloSlice = createSlice({
  initialState,
  name: 'drugoeDelo',
  reducers: {
    setTaskId: (draft, action: PayloadAction<number>) => {
      draft.taks_id = action.payload;
      sessionStorage.setItem(TASK_ID_KEY, String(action.payload));
    },
    clearTaskId: (draft) => {
      draft.taks_id = null;
      sessionStorage.removeItem(TASK_ID_KEY);
    },
    setNeedCheckTaskComplete: (draft, action: PayloadAction<boolean>) => {
      draft.needCheckTaskComplete = action.payload;
    },
  },
});

export const selectCurrentTask = (state: RootState) => state.drugoeDelo.taks_id;
export const selectNeedCheckTaskComplete = (state: RootState) =>
  state.drugoeDelo.needCheckTaskComplete;

export const { setTaskId, clearTaskId, setNeedCheckTaskComplete } = drugoeDeloSlice.actions;
