import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  AuthTeachbase: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loader: {
    width: 50,
    height: 50,
  },
});
