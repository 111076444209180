import React from 'react';
import Button from '@/shared/ui/Button/Button';
import classes from './steps.module.scss';
import { useNavigate } from 'react-router-dom';

export const SuccessfulPage = () => {
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate('/auth/signin');
  };

  return (
    <form onSubmit={handleSubmit}>
      <h1 className={classes.titleSecondVar}>Пароль успешно восстановлен</h1>
      <Button fullWidth type='submit' size={'small'} className={classes.button}>
        Войти
      </Button>
    </form>
  );
};
