import classes from './InputLabel.module.scss';
import React, { ReactNode } from 'react';
import {
  InputLabel as MuiInputLabel,
  InputLabelProps as MuiInputLabelProps,
} from '@material-ui/core';
import { ClassName } from '../../../models/ClassName/ClassName';
import classnames from 'classnames';

export interface InputLabelProps extends Omit<MuiInputLabelProps, 'variant'> {
  required?: boolean;
  variant?: 'default' | 'admin' | 'outlined';
  label: ReactNode;
  helperText?: ReactNode;
}

const InputLabel = (props: InputLabelProps) => {
  const {
    required,
    variant = 'default',
    label,
    helperText,
    className,
    ...muiInputLabelProps
  } = props;

  const _className = new ClassName(variant)
    .add(classnames(classes.root, className))
    .addVariant({
      variant: 'admin',
      className: classes.variantAdmin,
    })
    .toString();

  return (
    <MuiInputLabel {...muiInputLabelProps} className={_className}>
      <span>
        {label}
        {required && <mark>*</mark>}
      </span>

      {helperText && <h5>{helperText}</h5>}
    </MuiInputLabel>
  );
};

export default InputLabel;
