import React, { useMemo } from 'react';
import { Checkbox as MuiCheckbox, CheckboxProps as MuiCheckboxProps } from '@material-ui/core';
import { ReactComponent as AdminCheckedIcon } from './_images/adminCheckedIcon.svg';
import { ReactComponent as AdminIcon } from './_images/adminIcon.svg';
import { FormikProps } from 'formik';
import classNames from 'classnames';

export interface CheckboxProps extends MuiCheckboxProps {
  variant?: 'admin';
  formik?: FormikProps<any>;
  radioIconClassName?: string;
  radioCheckedIconClassName?: string;
}

const Checkbox = (props: CheckboxProps) => {
  const {
    variant,
    formik,
    name,
    checked: ownerChecked,
    radioCheckedIconClassName,
    radioIconClassName,
    ...muiCheckboxProps
  } = props;

  const checked = Array.isArray(formik?.values[name])
    ? formik?.values[name]?.find((v) => v == props.value)
    : formik?.values[name];

  const { icon, checkedIcon } = useMemo(() => {
    switch (variant) {
      case 'admin':
        return {
          icon: (
            <span className={classNames('text-gray-100', radioIconClassName)}>
              <AdminIcon />
            </span>
          ),
          checkedIcon: (
            <span className={classNames('text-red', radioCheckedIconClassName)}>
              <AdminCheckedIcon />
            </span>
          ),
        };
      default:
        return {};
    }
  }, [variant]);

  return (
    <MuiCheckbox
      name={name}
      icon={icon}
      checkedIcon={checkedIcon}
      checked={ownerChecked ?? checked}
      onChange={formik?.handleChange}
      onBlur={formik?.handleBlur}
      {...muiCheckboxProps}
    />
  );
};

export default Checkbox;
