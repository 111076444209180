import classes from './InputHelperText.module.scss';
import React, { DetailsHTMLAttributes } from 'react';
import classnames from 'classnames';

export interface InputHelperTextProps extends DetailsHTMLAttributes<any> {
  error?: boolean;
}

const InputHelperText = (props: InputHelperTextProps) => {
  const { error, children, className, ...divProps } = props;

  if (!children) {
    return <></>;
  }

  return (
    <div className={classnames(className, classes.root, { [classes.error]: error })} {...divProps}>
      {children}
    </div>
  );
};

export default InputHelperText;
