export const combineClasses = (...classes) => {
  let res = {};

  for (let classesObj of classes) {
    if (classesObj) {
      for (let classKey of Object.keys(classesObj)) {
        res[classKey] = (res[classKey] || '') + ' ' + classesObj[classKey];
      }
    }
  }

  return res;
};
