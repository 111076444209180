import { useDispatch } from 'react-redux';
import { setAppUpdateDate } from '_redux/appSlice';
import { useAppUpdate } from '@r-youth/app-update';
import { toast } from 'react-toastify';

const SOCKET_URL =
  process.env.NODE_ENV === 'development'
    ? 'wss://test.bolshajaperemena.ru'
    : 'wss://bolshayaperemena.online';

export const useAppUpdateNotification = () => {
  const dispatch = useDispatch();

  useAppUpdate({
    appType: 'th',
    socket: {
      endPoint: `${SOCKET_URL}:4010/notification`,
    },
    onUpdatePlanned: (date) => {
      dispatch(setAppUpdateDate({ date }));
    },
    onUpdateStarted: () => {
      toast.info('Начинается процесс обновления. Возвращайтесь к нам позже!');
      setTimeout(() => {
        window.location.href = window.location.origin;
      }, 1000);
    },
  });
};
