import classes from './DatePicker.module.scss';
import React, { ReactNode, useState } from 'react';
import { FormControl } from '@material-ui/core';
import InputLabel from '@/shared/ui/InputLabel/InputLabel';
import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import InputHelperText from '@/shared/ui/InputHelperText/InputHelperText';
import { FormikProps } from 'formik';
import { Moment } from 'moment';
import { ReactComponent as CalendarIcon } from '@/images/calendar.svg';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import moment from 'moment';
import clsx from 'clsx';

export interface DatePickerProps extends Omit<KeyboardDatePickerProps, 'onChange' | 'value'> {
  label?: ReactNode;
  labelHelperText?: ReactNode;
  formik?: FormikProps<any>;
  onChange?: (date: Moment) => any;
  value?: ParsableDate;
  minDate?: Moment;
  maxDate?: Moment;
}

const DatePicker = (props: DatePickerProps) => {
  const {
    label,
    labelHelperText,
    formik,
    value,
    className,
    onChange,
    onBlur,
    name,
    helperText,
    error,
    minDate,
    ...datePickerProps
  } = props;

  const formikErrorHelperText = formik?.touched[name] && formik?.errors[name];
  const _value = value || formik?.values[name] || null;
  const initialValue = formik?.values[name] ? moment(formik.values[name]) : null;
  const _error = Boolean(error || formikErrorHelperText);
  const [dateValue, setDateValue] = useState<Moment | null>(initialValue);

  const handleChange = (date: Moment | null) => {
    if (date && date.isBefore(minDate)) {
      formik.setFieldError(name, 'Укажите актуальный возраст');
      return;
    }

    setDateValue(date);
    if (onChange) {
      onChange(date);
    } else if (formik) {
      formik.handleChange({
        target: {
          name: name,
          value: date,
        },
      });
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel label={label} helperText={labelHelperText} className={'mb-2'} />

      <KeyboardDatePicker
        value={_value}
        className={clsx(className, classes.datepicker, { [classes.error]: _error })}
        format={'DD.MM.YYYY'}
        name={name}
        keyboardIcon={<CalendarIcon />}
        onChange={handleChange}
        onBlur={formik?.handleBlur}
        invalidDateMessage={null}
        {...datePickerProps}
      />

      <InputHelperText error={_error} className={classes.errorText}>
        {(helperText || formikErrorHelperText) as ReactNode}
      </InputHelperText>
    </FormControl>
  );
};

export default DatePicker;
