import React, { ForwardedRef } from 'react';
import classes from './Button.module.scss';
import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@material-ui/core';
import clsx from 'clsx';
import { Loader } from '@/shared/ui/Loader';

export type ButtonProps = Omit<MuiButtonProps, 'color' | 'size' | 'variant'> & {
  variant?:
    | 'text'
    | 'outlined'
    | 'contained'
    | 'mui'
    | 'muiBold'
    | 'admin'
    | 'adminContained'
    | 'adminOutlined'
    | 'flat';
  color?: 'transparent' | 'white' | 'red' | 'green' | 'gray' | 'blue' | 'violet';
  size?: 'medium' | 'small';
  loading?: boolean;
};

const Button = React.forwardRef((props: ButtonProps, ref: ForwardedRef<any>) => {
  const {
    variant = 'contained',
    color = 'red',
    size = 'medium',
    className,
    disabled,
    loading,
    ...rest
  } = props;

  return (
    <MuiButton
      className={clsx(className, {
        // Common
        [classes.common]: ['text', 'contained', 'outlined'].includes(variant),
        // Size small
        [classes.small]: size === 'small',
        // Size medium
        [classes.medium]: size === 'medium',
        // Text
        [classes.text]: variant === 'text',
        [classes.text_gray]: variant === 'text' && color === 'gray',
        [classes.text_blue]: variant === 'text' && color === 'blue',
        // Contained
        [classes.contained_disabled]: variant === 'contained' && (disabled || loading),
        [classes.contained_red]: variant === 'contained' && color === 'red' && !disabled,
        [classes.contained_blue]: variant === 'contained' && color === 'blue' && !disabled,
        [classes.contained_gray]: variant === 'contained' && color === 'gray' && !disabled,
        [classes.contained_green]: variant === 'contained' && color === 'green' && !disabled,
        // Outlined
        [classes[`outlined_${color}`]]: variant === 'outlined',
      })}
      {...rest}
      disabled={loading || disabled}
      endIcon={loading ? <Loader /> : rest.endIcon}
    />
  );
});

export default Button;
