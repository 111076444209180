import { createAsyncThunk } from '@reduxjs/toolkit';
import { instance } from 'api/instance';
import { doRequest } from 'hooks/doRequest';
import { setAppState } from './appSlice';
import { AuthenticationStatus } from '../_constants/authentication-status';

export const fetchGenerateCertificate = createAsyncThunk(
  'fetchGenerateCertificate',
  async ({ certificateId }: any) => {
    return doRequest(() => instance.get(`/api/certs/${certificateId}/generate`), null, null, {
      showSuccess: true,
      successMsg: 'Сертификат успешно сгенерирован',
    });
  },
);

export const fetchDownloadCertificate = createAsyncThunk(
  'fetchDownloadCertificate',
  async ({ certificateId }: any) => {
    const jwt = localStorage.getItem('JWT');
    window.open(`/api/certs/${certificateId}/download?jwt=${jwt}`, '_blank');
  },
);

export const fetchRegions = createAsyncThunk('fetchRegions', async () => {
  return doRequest(() => instance.get('/api/regions'));
});

export const fetchGuilds = createAsyncThunk(
  'fetchGuilds',
  async ({ regionId, educationLevel }: any) => {
    return doRequest(() =>
      instance.get(`/api/educational-institutions-from-region-level/${regionId}/${educationLevel}`),
    );
  },
);

export const fetchApplyEvent2022 = createAsyncThunk('fetchApplyEvent2022', async () => {
  return doRequest(() => instance.post('/api/users/contest2022_app'));
});
