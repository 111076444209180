import { Color, Size } from './types';

export const getFontSizeClassName = (size: Size) => {
  return `vim-font-size-${size}`;
};

export const getColorClassName = (color: Color) => {
  return `vim-color-${color}`;
};

export const getLetterSpacingClassName = (size: Size) => {
  return `vim-letter-spacing-${size}`;
};

export const getLineHeightClassName = (size: Size) => {
  return `vim-line-height-${size}`;
};
