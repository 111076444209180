import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../_redux/store';

export const useCheatCode = (sequence) => {
  const { currentCheat } = useSelector((r: RootState) => r.cheats);
  const [equal, setEqual] = useState(false);

  useEffect(() => {
    if (currentCheat === sequence) {
      setEqual(!equal);
    }
  }, [currentCheat]);

  return {
    enabled: equal,
  };
};
