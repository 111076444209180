import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, makeStyles } from '@material-ui/core';
import { combineClasses } from '@/shared/utils/combineClasses';
import { ReactComponent as BlackWithRedLogo } from 'images/black-with-white-logo.svg';
import authPagesBG from 'images/authPagesBGDesktop.png';
import authPagesIMG from 'images/authPagesIMG.png';
import authPagesBGIcon from 'images/authPagesBGIcon.png';
import BrandLink from '@/shared/ui/BrandLink/BrandLink';
import { xs } from '@/app/themes/themeInstance';
import { Image } from '@/shared/ui/Image/Image';
import { setRegisterSliceState } from '_redux/register/register.slice';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    padding: '45px 115px',
    backgroundColor: '#F0552D',
    background: `url(${authPagesBG}) center center/cover`,
    [xs]: {
      padding: '45px 16px',
      flexDirection: 'column',
      background: 'none',
      backgroundColor: '#F0552D',
    },
  },
  liveIMGContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    [xs]: {
      display: 'none',
    },
  },
  logo: {
    position: 'absolute',
    left: '48px',
    top: '48px',
    zIndex: 2,
    cursor: 'pointer',
    [xs]: {
      position: 'static',
      width: '100%',
      marginBottom: '32px',
    },
  },
  mobileBGIcon: {
    position: 'fixed',
    bottom: '-270px',
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'none',
    [xs]: {
      display: 'block',
    },
  },
}));

const RegisterPageContainer = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = combineClasses(props.classes, useStyles());

  const handleNavigate = () => {
    dispatch(
      setRegisterSliceState({
        vkIdKey: null,
      }),
    );
    navigate('/');
  };

  return (
    <div className={classes.root}>
      <div className={classes.logo} role='button' onClick={handleNavigate}>
        <BlackWithRedLogo width={'100%'} />
      </div>
      <Box display={'flex'} justifyContent={'space-around'} alignItems={'center'} width={'100%'}>
        <div className={classes.liveIMGContainer}>
          <Image src={authPagesIMG} alt='Живу и создаю в России' width={'100%'} />
        </div>
        {props.children}
      </Box>
      <Image src={authPagesBGIcon} className={classes.mobileBGIcon} />
    </div>
  );
};

export default RegisterPageContainer;
