import classNames from 'classnames';
import { VISUALLY_IMPAIRED_MODE_CLASS } from '@/features/visuallyImpairedMode/constants';
import { Size } from '@/features/visuallyImpairedMode/types';
import classes from './VisuallyImpairedFontSizes.module.scss';
import { useVisuallyImpariedMode } from '@/features/visuallyImpairedMode/provider';

export const VisuallyImpairedFontSizes = () => {
  const { setConfig, config } = useVisuallyImpariedMode();

  const renderButtons = () => {
    return Object.values(Size).map((size) => {
      return (
        <button
          key={size}
          className={classNames(`${VISUALLY_IMPAIRED_MODE_CLASS}-font-btn-${size}`, classes.btn, {
            [classes.active]: config.fontSize === size,
          })}
          onClick={() => setConfig('fontSize', size)}
        >
          А
        </button>
      );
    });
  };

  return <div className={classes.root}>{renderButtons()}</div>;
};
