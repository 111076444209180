import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import RegisterFormContainer from '../RegisterPage/RegisterFormContainer';
import Button from '@/shared/ui/Button/Button';
import Link from '@/shared/ui/Link/Link';
import classes from './index.module.scss';
import { useLogoutVkUserMutation } from '_services/authApi';
import { setRegisterSliceState } from '_redux/register/register.slice';

const VkIdNotFound = () => {
  const [triggerLogout] = useLogoutVkUserMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = async () => {
    await triggerLogout({});
    dispatch(
      setRegisterSliceState({
        vkIdKey: null,
      }),
    );
    navigate('/');
  };

  return (
    <RegisterFormContainer className={classes.container} title='Пользователь не найден'>
      <p>
        Для привязки профиля «ВКонтакте» нужно пройти{' '}
        <Link to='/auth/signup' className={classes.link}>
          регистрацию
        </Link>{' '}
        или{' '}
        <Link to='/auth/signin' className={classes.link}>
          авторизоваться
        </Link>
        , если уже есть профиль в проекте “Твой Ход”
      </p>
      <Button fullWidth size={'small'} onClick={logout}>
        Вернуться на главную
      </Button>
    </RegisterFormContainer>
  );
};

export default VkIdNotFound;
