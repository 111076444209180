import classNames from 'classnames';
import { ImgHTMLAttributes } from 'react';
import classes from './Image.module.scss';

export type ImageProps = ImgHTMLAttributes<HTMLImageElement> & {
  aspectRatio?: {
    width: number;
    height: number;
  };
};

export const Image = ({ style, aspectRatio, className, ...props }: ImageProps) => {
  return (
    <img
      loading='lazy'
      className={classNames(classes.image, className)}
      style={{
        ...(aspectRatio && {
          aspectRatio: `${aspectRatio.width} / ${aspectRatio.height}`,
        }),
        ...style,
      }}
      {...props}
    />
  );
};
