import React from 'react';
import CheckedIcon from './_images/check-icon.svg';
import { makeStyles } from '@material-ui/core';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 16,
    height: 16,
    border: `2px solid`,
    borderRadius: 2,
    transition: 'all .2s ease',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& img': {
      maxWidth: '90%',
    },
  },
  rootPrimary: {
    borderColor: '#fff',
  },
  rootPrimaryChecked: {
    borderColor: theme.palette.blackGray.main,
  },
}));

export const BrandButtonCheckbox = ({ checked }) => {
  const classes = useStyles();

  const rootClasses = classnames({
    [classes.root]: true,
    [classes.rootPrimary]: !checked,
    [classes.rootPrimaryChecked]: checked,
  });

  return <div className={rootClasses}>{checked && <img src={CheckedIcon} />}</div>;
};
