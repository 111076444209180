import React from 'react';
import LkState from 'context/LkContext/LkState';
import AuthState from 'context/AuthContext/AuthState';
import AdminUserState from 'context/AdminUserContext/AdminUserState';
import { store } from '_redux/store';
import { Provider } from 'react-redux';

const GlobalContext = ({ children }) => {
  return (
    <Provider store={store}>
      <AuthState>
        <AdminUserState>
          <LkState>{children}</LkState>
        </AdminUserState>
      </AuthState>
    </Provider>
  );
};

export default GlobalContext;
