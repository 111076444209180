import { ReactNode, createContext, useContext } from 'react';
import { ContextValue } from './types';
import { useModel } from './model';

const VisuallyImpariedModeContext = createContext<ContextValue>(null);

export const VisuallyImpariedModeProvider = (props: { children: ReactNode }) => {
  const { children } = props;
  const model = useModel();

  return (
    <VisuallyImpariedModeContext.Provider value={model}>
      {children}
    </VisuallyImpariedModeContext.Provider>
  );
};

export const useVisuallyImpariedMode = () => useContext(VisuallyImpariedModeContext);
