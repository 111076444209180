import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { restoreSendPhoneCode } from 'api/AuthApi';
import { doRequest } from 'hooks/doRequest';
import Button from '@/shared/ui/Button/Button';
import TextField from '@/shared/ui/TextField';
import { restoreSendEmailForCode } from 'api/AuthApi';
import classes from './steps.module.scss';

export const PhoneCodeEnter = ({
  saveToRequestData,
  nextStep,
  email,
  attemptEmail,
  setAttemptEmail,
  seconds,
  tries_left,
}) => {
  let clearTimer = React.useRef(null);
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [timer, setTimer] = useState(null);
  const [status, setStatus] = useState(null);
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: yup.object().shape({
      code: yup.string().required(),
    }),
    onSubmit: () => handleSubmit(),
  });

  useEffect(() => {
    if (seconds) {
      startingTimer();
    }
    return () => {
      clearInterval(clearTimer.current);
    };
    //eslint-disable-next-line
  }, [seconds]);

  useEffect(() => {
    if (timer === 0) {
      clearInterval(clearTimer.current);
      setVisible(false);
    }
  }, [timer]);

  const startingTimer = () => {
    if (clearTimer.current) {
      clearInterval(clearTimer.current);
    }
    setTimer(seconds);

    clearTimer.current = setInterval(() => {
      setTimer((prev) => {
        return prev - 1;
      });
    }, 1000);
  };

  const handleSubmit = async () => {
    setError(false);
    setIsFetching(true);

    const data = await doRequest(restoreSendPhoneCode, { email, code: formik.values.code });
    setIsFetching(false);

    if (data.success) {
      saveToRequestData({ code: formik.values.code });
      nextStep();
    } else {
      setError(data.errors);
      setStatus(data.status);
    }
  };

  const codeAgain = async () => {
    setError(false);
    setStatus(null);
    setVisible(true);

    if (tries_left.current === 1) setLoading(true);
    const data = await doRequest(restoreSendEmailForCode, { email });
    tries_left.current = data?.data?.tries_left;

    if (data.success && data.data.tries_left === 0) {
      setAttemptEmail(false);
    } else if (data.success && data.data.tries_left > 0) {
      startingTimer();
      setAttemptEmail(true);
    }
  };

  const renderError = () => {
    if (!attemptEmail && status === 423) {
      return (
        <span className={classes.error}>
          Вы исчерпали суточный лимит отправки и ввода кода. Попробуйте завтра.
        </span>
      );
    } else if (status === 423 && attemptEmail) {
      return (
        <span className={classes.error}>
          Вы исчерпали максимальное количество попыток ввода кода.
        </span>
      );
    } else if (error) {
      return <span className={classes.error}>Неверный код&nbsp;</span>;
    }
  };

  const renderRepeatCodeAndError = () => {
    if (tries_left.current > 0 && !loading) {
      if ((timer !== 0 && attemptEmail) || (visible && attemptEmail)) {
        return (
          <p className={classes.repeatCode}>
            Отправить код повторно ({timer > 0 ? timer : seconds})
          </p>
        );
      } else if (attemptEmail) {
        return (
          <span className={classes.repeatCode} onClick={codeAgain}>
            Отправить код повторно
          </span>
        );
      }
    }
  };

  const renderButton = () => {
    if (!attemptEmail && status === 423) {
      return (
        <Button fullWidth shape='rounded' color='brandGreen' onClick={() => navigate('/')}>
          Перейти на главную
        </Button>
      );
    }

    return (
      <Button
        fullWidth
        type='submit'
        size={'small'}
        disabled={
          formik.values?.code?.replace(/\s/gi, '').length < 1 || isFetching || status === 423
        }
        className={classes.button}
      >
        Подтвердить
      </Button>
    );
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <h1 className={classes.titleCodeEnter}>Восстановление пароля</h1>
      <p className={classes.promptText}>
        На указанную электронную почту было выслано письмо с кодом для подтверждения.
      </p>
      <TextField
        name='code'
        placeholder=' '
        fullWidth
        formik={formik}
        code
        label='Код подтверждения'
        className={classes.code}
      />
      {renderError()}
      {renderRepeatCodeAndError()}

      {renderButton()}
    </form>
  );
};
