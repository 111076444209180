import { useRef, useState } from 'react';

const defaultOptions = {
  multiple: false,
};

export const useFileInput = (options: { multiple?: boolean; onChange: (file: File) => void }) => {
  options = { ...defaultOptions, ...options };
  const { multiple, onChange } = options;

  const [state, setState] = useState({
    file: null,
  });

  const { file } = state;

  const inputRef = useRef(null);

  const selectFile = () => {
    inputRef.current?.click();
  };

  const handleChange = (e) => {
    const file = multiple ? e.target.files : e.target.files[0];
    setState((prevState) => ({ ...prevState, file }));
    e.target.value = null;
    onChange?.(file);
  };

  return {
    file,
    inputRef,
    selectFile,
    handleChange,
  };
};
