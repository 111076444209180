import { createAsyncThunk } from '@reduxjs/toolkit';
import { doRequest } from 'hooks/doRequest';
import { instance } from 'api/instance';

export const fetchRequestRegisterEmailCode = createAsyncThunk<any, any>(
  'register/fetchRequestEmailCode',
  async (payload) => {
    const { email } = payload;
    return doRequest(() => instance.post('/api/auth/email/send-code', { email }));
  },
);

export const fetchSubmitRegisterEmailCode = createAsyncThunk<any, any>(
  'register/fetchSubmitEmailCode',
  async (payload) => {
    const { code, email } = payload;
    const formData = new FormData();
    formData.append('email', email);
    formData.append('code', code);
    return doRequest(() => instance.post('/api/auth/email/check-code', formData));
  },
);

export const fetchSubmitRegisterUserdata = createAsyncThunk<any, any>(
  'register/fetchSubmitUserdata',
  async (payload) => {
    const {
      email,
      code,
      name,
      phone,
      education_level,
      region,
      educational_institution_id,
      educational_institution_info,
      course,
      is_notify,
      plain_password,
      ambassador,
    } = payload;

    const formData = new FormData();
    formData.append('email', email);
    formData.append('code', code);
    formData.append('user_register[name][middle_name]', name.middle_name);
    formData.append('user_register[name][first_name]', name.first_name);
    formData.append('user_register[name][last_name]', name.last_name);
    formData.append('user_register[phone]', phone);
    formData.append('user_register[education_level]', education_level);
    formData.append('user_register[region]', region);
    formData.append('user_register[educational_institution_id]', educational_institution_id);
    formData.append('user_register[educational_institution_info]', educational_institution_info);
    formData.append('user_register[course]', course);
    // formData.append('user_register[is_notify]', '0');
    formData.append('user_register[plain_password]', plain_password);

    if (ambassador) {
      formData.append('user_register[ambassador]', ambassador);
    }

    return doRequest(() => instance.post('/api/auth/register/form', formData));
  },
);
