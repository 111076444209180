import { useEffect } from 'react';

const setCookie = (name: string, value: string, days: number) => {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + days);
  const expires = `expires=${expirationDate.toUTCString()}`;
  document.cookie = `${name}=${encodeURIComponent(value)}; ${expires}; path=/`;
};

export const useUTMCookie = (): void => {
  useEffect(() => {
    const queryString = decodeURIComponent(window.location.search);
    const queryParams = new URLSearchParams(queryString);

    const utmParams = ['utm_source', 'utm_medium', 'utm_campaign'];

    utmParams.forEach((param: string) => {
      const value = queryParams.get(param);
      if (value) {
        setCookie(param, value, 365);
      }
    });
  }, []);
};
