import React from 'react';
import { Forgot } from '@/widgets/Forgot';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAuthStyles } from './styles';
import { AppSliceState } from '_redux/appSlice';
import { useSelector } from 'react-redux';
import RegisterPageContainer from './RegisterPage/RegisterPageContainer';
import LoginPage from './LoginPage/LoginPage';
import RegisterPage from './RegisterPage/RegisterPage';
import VkIdNotFound from './VkIdNotFound';
import { RootState } from '../../_redux/store';
import { AuthenticationStatus } from '../../_constants/authentication-status';

export default function AuthPage() {
  const c = useAuthStyles();
  const { authenticationStatus } = useSelector<RootState, AppSliceState>((r) => r.app);

  if (authenticationStatus === AuthenticationStatus.AUTHORIZED) {
    return <Navigate to={'/lk/profile'} replace />;
  }

  return (
    <RegisterPageContainer>
      <div className={c.authContainer}>
        <Routes>
          <Route path='auth' element={<Navigate to='/auth/signup' replace />} />
          <Route path='signin' element={<LoginPage />} />
          <Route path='signup' element={<RegisterPage />} />
          <Route path='forgot' element={<Forgot />} />
          <Route path='vk-not-found' element={<VkIdNotFound />} />
        </Routes>
      </div>
    </RegisterPageContainer>
  );
}
