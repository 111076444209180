import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '_redux/store';

type State = {
  codeErrorDate: string | null;
};

export const forumSlice = createSlice({
  name: 'forum',
  initialState: {
    codeErrorDate: null,
  },
  reducers: {
    setCodeErrorDate: (state, action: PayloadAction<string>) => {
      state.codeErrorDate = action.payload;
    },
  },
});

export const { setCodeErrorDate } = forumSlice.actions;

export const selectCodeErrorDate = (state: RootState) => state.forum.codeErrorDate;
