import { isNull, isUndefined } from 'lodash';
import moment from 'moment';
import { MaskedInputDateFormat } from '@/shared/ui/TextField/_constants/MaskedInputDateFormat';

type DataType = 'form-data' | 'object';

type BuilderCreateReturn<Type extends DataType> = Type extends 'form-data' ? FormData : object;

export class FormDataBuilder {
  static build(prefix: string, formikValues: any, options?: BuildOptions): FormData {
    const formData = new FormData();
    const extractedValues = this.extractFormikValues(formikValues, '', options);

    for (let extractedValue of extractedValues) {
      formData.append(`${prefix}${extractedValue.path}`, extractedValue.value);
    }

    return formData;
  }

  private static extractFormikValues = (
    formikValues: any,
    path: string = '',
    options?: BuildOptions,
  ): { path: string; value: string }[] => {
    const { skipEmpty = false } = { ...options };
    const values = [];

    for (let fieldName in formikValues) {
      const formikValue = formikValues[fieldName];
      const formikValuePath = `${path}[${fieldName}]`;

      if (!formikValue && skipEmpty) continue;

      if (typeof formikValue === 'string' || typeof formikValue === 'number') {
        values.push({ path: formikValuePath, value: formikValue.toString() });
      }

      if (typeof formikValue === 'boolean') {
        values.push({ path: formikValuePath, value: formikValue ? '1' : '0' });
      }

      if (typeof formikValue === 'object') {
        if (formikValue instanceof moment) {
          values.push({
            path: formikValuePath,
            value: moment(formikValue).format(MaskedInputDateFormat),
          });
        } else if (formikValue instanceof Blob) {
          values.push({ path: formikValuePath, value: formikValue });
        } else {
          values.push(...this.extractFormikValues(formikValue, formikValuePath, options));
        }
      }

      if (formikValue === null || formikValue === undefined) {
        values.push({ path: formikValuePath, value: '' });
      }
    }

    return values;
  };

  private static _deepMap = (data: FormData | {}, values: object, wrapper?: string) => {
    Object.entries(values).forEach(([key, value]) => {
      if (isNull(value) || isUndefined(value)) {
        return;
      }

      if (typeof value === 'object' && !(value instanceof Blob)) {
        if (wrapper) {
          this._deepMap(data, value, `${wrapper}[${key}]`);
        } else {
          this._deepMap(data, value, key);
        }
      } else {
        let _value: string | Blob;

        if (typeof value === 'boolean') {
          _value = Number(value).toString();
        } else {
          _value = value;
        }

        const isFormData = data instanceof FormData;
        if (wrapper) {
          if (isFormData) {
            data.append(`${wrapper}[${key}]`, _value);
          } else {
            data[`${wrapper}[${key}]`] = _value;
          }
        } else {
          if (isFormData) {
            data.append(key, _value);
          } else {
            data[key] = _value;
          }
        }
      }
    });
  };

  static create = <T extends object | [], Type extends DataType = 'form-data'>(
    values: T,
    wrapper?: string,
    type?: Type,
  ): BuilderCreateReturn<Type> => {
    const _type = type || 'form-data';

    if (_type === 'form-data') {
      const formData = new FormData();
      this._deepMap(formData, values, wrapper);

      return formData;
    } else {
      const data = {};
      this._deepMap(data, values, wrapper);
      return data as BuilderCreateReturn<Type>;
    }
  };
}

type BuildOptions = {
  skipEmpty: boolean;
};
