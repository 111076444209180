import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { restoreSendEmailForCode } from 'api/AuthApi';
import { doRequest } from 'hooks/doRequest';
import Button from '@/shared/ui/Button/Button';
import TextField from '@/shared/ui/TextField/TextField';
import classes from './steps.module.scss';

export const EmailEnter = ({
  saveToRequestData,
  nextStep,
  setAttemptEmail,
  setSeconds,
  tries_left,
}) => {
  const [isFetching, setIsFetching] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: yup.object().shape({
      email: yup.string().required(),
    }),
    onSubmit: () => handleSubmit(),
  });

  const handleSubmit = async () => {
    setIsFetching(true);

    const { success, data } = await doRequest(restoreSendEmailForCode, {
      email: formik.values.email.toLowerCase(),
    });
    setIsFetching(false);
    tries_left.current = data?.tries_left;

    if (success) {
      setSeconds(data.seconds);
      if (data.tries_left === 0) {
        setAttemptEmail(false);
      } else {
        setAttemptEmail(true);
      }
      saveToRequestData({ email: formik.values.email.toLowerCase() });
      nextStep();
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <h1 className={classes.title}>Восстановление пароля</h1>
      <TextField
        formik={formik}
        name='email'
        label='Укажите адрес электронной почты'
        className={classes.input}
      />
      <Button
        fullWidth
        type='submit'
        size={'small'}
        disabled={formik.values.email.replace(/\s/gi, '').length < 1 || isFetching}
        className={classes.button}
      >
        Продолжить
      </Button>
    </form>
  );
};
