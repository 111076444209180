import { useCallback, useMemo, useState, useRef } from 'react';
import { isNull, isUndefined, omitBy } from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';

export const useUrlSearchParams = <T extends object>(
  init?: T,
  type: 'push' | 'replace' = 'push',
) => {
  const isInit = useRef(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [params, _setParams] = useState<T>(init);

  const searchParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const updateSearch = useCallback(() => {
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: type === 'replace' });
  }, [navigate, location.pathname, searchParams, type]);

  const setParams = useCallback(
    (newParams: Record<string, string> = {}) => {
      _setParams((prev) => ({
        ...prev,
        ...omitBy(newParams, (value) => isNull(value) || isUndefined(value)),
      }));
      Object.entries(newParams).forEach(([key, value]) => {
        if (isNull(value) || isUndefined(value) || value === '') {
          searchParams.delete(key);
        } else {
          searchParams.set(key, value);
        }
      });
      updateSearch();
    },
    [searchParams, updateSearch],
  );

  const reset = useCallback(() => {
    navigate(location.pathname, { replace: type === 'replace' });
    _setParams(undefined);
  }, [navigate, location.pathname, type]);

  // Без useEffect для синхронной загрузки параметров
  if (!isInit.current) {
    if (!init) {
      const search = new URLSearchParams(location.search);
      const _params = {};
      search.forEach((value, key) => {
        _params[key] = value;
      });
      setParams(_params);
      isInit.current = true;
      return [_params as T, setParams, reset] as const;
    }

    const _params = Object.fromEntries(
      Object.entries(init).map(([key, value]) => [key, searchParams.get(key) ?? value]),
    );
    _setParams(_params as T);
    isInit.current = true;
    return [_params as T, setParams, reset] as const;
  }

  return [params as T, setParams, reset] as const;
};
