import React from 'react';
import ReactTextMaskInput from 'react-text-mask';

export const MaskedInput = React.forwardRef((props: any, ref) => {
  return <ReactTextMaskInput {...props} />;
});

export const getMaskedInput = (mask) =>
  React.forwardRef(({ inputRef, ...props }: any, ref) => <MaskedInput mask={mask} {...props} />);

export const PhoneMaskedInput = getMaskedInput([
  '+',
  '7',
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
]);

export const DateMaskedInput = getMaskedInput([
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]);
