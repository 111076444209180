// @ts-nocheck
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import React from 'react';
import { theme } from '@/app/themes/themeInstance';
import AppRoutes from '@/app/AppRoutes';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';
import '../fonts/fonts.css';
import 'moment/locale/ru';
import TextExplosionEasterEgg from '@/widgets/UbahEasterEgg/TextExplosionEasterEgg';
import { StylesProvider } from '@material-ui/styles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useAppUpdateNotification } from '@/hooks/useAppUpdateNotification';
import { useDarkMode } from '@/hooks/useDarkMode';
import { useUserActivityLogger } from '@/hooks/useUserActivityLogger';
import { useReferralLinks } from '@/hooks/useReferralLinks';
import { useUTMCookie } from '@/hooks/useUTMCookie';
moment.locale('ru');

const App = () => {
  useDarkMode();
  useUserActivityLogger();
  useReferralLinks();
  useUTMCookie();
  useAppUpdateNotification();

  return (
    <MuiThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={MomentUtils} locale={'ru'}>
          <TextExplosionEasterEgg>
            <AppRoutes />
          </TextExplosionEasterEgg>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </MuiThemeProvider>
  );
};

export default App;
