import React, { useReducer, useState, useContext, useEffect } from 'react';
import { AdminUserContext } from './AdminUserContext';
import { adminUserReducer } from './AdminUserReducer';
import { instance } from 'api/instance';
import { doRequest } from 'hooks/doRequest';

const AdminUserState = ({ children }) => {
  let clearTime;

  const initialState = {
    users: {},
    schools: {},
    roles: [],
    regions: [],
    municipal: [],
    userData: {},
    schoolData: {},
    responsible_school: [],
    current_reward_user: [],
    rewardList: [],
    federalDistricts: [],
    applications: {},
    partners: [],
  };

  const [state, dispatch] = useReducer(adminUserReducer, initialState);
  const [noRoute, setNoRoute] = useState(null);

  const getUsers = async (id = 1, count = 10, params) => {
    try {
      const result = await instance.get(`/api/admin/users/list/${id}/${count}`, { params });
      dispatch({ type: 'GET_LIST', payload: result.data });
    } catch (err) {
      throw err;
    }
  };

  const getSchools = async (id = 1, count = 10, params) => {
    try {
      const result = await instance.get(`/api/admin/educational-institution/list/${id}/${count}`, {
        params,
      });
      dispatch({ type: 'GET_LIST_SCHOOL', payload: result.data });
      return result.data.list;
    } catch (err) {
      throw err;
    }
  };

  const getRoles = async () => {
    try {
      const result = await instance.get(`/api/admin/roles/list/1`);
      dispatch({ type: 'GET_ROLES', payload: result.data.list });
    } catch (err) {
      throw err;
    }
  };

  const getRegions = async () => {
    try {
      const { data } = await instance.get(`/api/region-list`);
      dispatch({ type: 'GET_REGIONS', payload: data.list });
    } catch (err) {
      throw err;
    }
  };

  const getFederalDistricts = async () => {
    try {
      const { data } = await instance.get('/api/admin/manual/districts');
      dispatch({ type: 'GET_FEDERAL_DISTRICTS', payload: data });
    } catch (err) {
      throw err;
    }
  };

  const getMunicipal = async (id) => {
    try {
      const result = await instance.get(`/api/territory/municipal_units/${id}`);
      return result.data;
    } catch (err) {
      throw err;
    }
  };

  const getSchoolById = async (id) => {
    try {
      const result = await instance.get(`/api/school/${id}`);
      return result.data;
    } catch (err) {
      throw err;
    }
  };

  const getDataUserCard = async (id) => {
    try {
      const result = await instance.get(`/api/admin/users/show/${id}`);
      dispatch({ type: 'GET_DATA_USER_CARD', payload: result.data });
      return result.data;
    } catch (err) {
      return false;
    }
  };

  const createNewUser = async (body) => {
    try {
      const result = await instance.post(`/api/admin/users/create`, body);
      return result.data;
    } catch (err) {
      throw err;
    }
  };

  const editUser = async (id, body) => {
    try {
      const result = await instance.patch(`/api/admin/users/edit/${id}`, body);
      return result.data;
    } catch (err) {
      throw err;
    }
  };

  const deleteUserCard = async (id) => {
    try {
      return await instance.delete(`/api/admin/users/remove/${id}`);
    } catch (err) {
      throw err;
    }
  };

  //получаем данные по карточке школы
  const getDataSchoolCard = async (id) => {
    try {
      const result = await instance.get(`/api/admin/educational-institution/${id}`);
      dispatch({ type: 'GET_DATA_SCHOOL_CARD', payload: result.data });
      return result.data;
    } catch (err) {
      return false;
    }
  };

  // Получаем список ответственных за школу
  const getResponsiblesForSchool = async (id) => {
    try {
      const result = await instance.get(
        `/api/admin/educational-institution/possible-responsibles/${id}`,
      );
      dispatch({ type: 'GET_RESPONSIBLE_SCHOOL', payload: result.data });
      return result.data;
    } catch (err) {
      throw err;
    }
  };

  //удаление карточки школы
  const deleteSchoolCard = async (id) => {
    try {
      return await instance.delete(`/api/admin/educational-institution/${id}`);
    } catch (err) {
      throw err;
    }
  };

  const newSchoolCard = async (body) => {
    try {
      const result = await instance.put(`/api/admin/educational-institution/`, body);
      return result.data;
    } catch (err) {
      throw err;
    }
  };

  const editSchoolCard = async (id, body) => {
    try {
      const result = await instance.post(`/api/admin/educational-institution/${id}`, body);
      return result.data;
    } catch (err) {
      throw err;
    }
  };

  const onChangeSearchFieldInn = (event, { setLoadingSearch }) => {
    if (clearTime) clearTimeout(clearTime);
    const { value } = event.target;

    return new Promise((resolve, reject) => {
      clearTime = setTimeout(() => {
        if (value.length === 10) {
          setLoadingSearch(true);
          instance
            .get(`/api/suggestions/by-inn/${value}`)
            .then((data) => {
              setLoadingSearch(false);
              resolve(data);
            })
            .catch((err) => {
              setLoadingSearch(false);
              reject([]);
              throw err;
            });
        }
      }, 1000);
    });
  };

  const getMuinicipalOktmo = async (oktmo) => {
    try {
      const data = await instance.get(`/api/territory/by-oktmo/${oktmo}`);
      return data.data;
    } catch (err) {
      throw err;
    }
  };

  //выгрузка по юзерам ТХ
  const getExcel = async (params) => {
    try {
      const { data } = await instance.get('/api/admin/users/export', { params });
      return data;
    } catch (err) {
      throw err;
    }
  };

  //полная выгрузка по юзерам ТХ
  const getExcelAll = async () => {
    try {
      const { data } = await instance.get('/api/admin/users/export_all');
      return data;
    } catch (err) {
      throw err;
    }
  };

  //выгрузка по школам
  const getExcelSchool = async (params) => {
    try {
      const { data } = await instance.get('/api/admin/educational-institution/export', { params });
      return data;
    } catch (err) {
      throw err;
    }
  };

  //получение институтов по региону
  const institutionsByRegion = async (id) => {
    try {
      const { data } = await instance.get(`/api/educational-institutions-from-region/${id}`);
      return data;
    } catch (err) {
      throw err;
    }
  };

  //отклонить ОУ (образовательное учреждение)
  const rejectInstituteHandler = async (id) => {
    try {
      const { data } = await instance.post(`/api/admin/educational-institution/${id}/reject`);
      return data;
    } catch (err) {
      throw err;
    }
  };

  //подтвердить ОУ (образовательное учреждение)
  const confirmInstituteHandler = async (id, body) => {
    try {
      const { data } = await instance.post(
        `/api/admin/educational-institution/${id}/confirm`,
        body,
      );
      return data;
    } catch (err) {
      throw err;
    }
  };

  //История начисления баллов(награды) для конкретного пользователя
  const rewardHistory = async (id) => {
    try {
      const { data } = await instance.get(`/api/admin/users/${id}/reward_history`);
      dispatch({ type: 'CURRENT_REWARD_USER', payload: data.reverse() });
    } catch (err) {
      throw err;
    }
  };

  //получение списка по наградам
  const getRewardLisrt = async () => {
    try {
      const { data } = await instance.get('/api/admin/reward/list');
      const newData = data.map((i) => ({ id: i.id, title: i.name, score: i.score }));
      dispatch({ type: 'GET_REWARD_LIST', payload: newData });
    } catch (err) {
      throw err;
    }
  };

  //добавление награды
  const addReward = async (idUser, idReward) => {
    try {
      const { data } = await instance.post(`/api/admin/users/${idUser}/add_reward/${idReward}`);
      if (data.result) {
        dispatch({
          type: 'CURRENT_REWARD_USER',
          payload: [data.data, ...state.current_reward_user],
        });
      }
    } catch (err) {
      throw err;
    }
  };

  // удаление награды
  const deleteReward = async (idUser, idReward) => {
    try {
      const { data } = await instance.delete(
        `/api/admin/users/${idUser}/remove_reward/${idReward}`,
      );

      if (data.result) {
        let current_reward_user = [...state.current_reward_user];
        current_reward_user = current_reward_user.filter((item) => item.reward.id != idReward);

        dispatch({ type: 'CURRENT_REWARD_USER', payload: current_reward_user });
      }

      return data;
    } catch (err) {
      throw err;
    }
  };

  const fetchUploadVerification = async (file) => {
    const _formData = new FormData();
    _formData.append('verification[file]', file);

    return await doRequest(() => {
      return instance.post('/api/admin/users/ou_verification', _formData);
    });
  };

  const getApplications = async (id = 1, count = 10, params) => {
    try {
      const result = await instance.get(`/api/admin/community/team_app/list/${id}/${count}`, {
        params,
      });
      dispatch({ type: 'GET_APPLICATIONS', payload: result.data });
    } catch (err) {
      throw err;
    }
  };

  const getPartners = async (page = 1, perPage = 10) => {
    try {
      const result = await instance.get(`/api/admin/partner/list/${page}/${perPage}`);
      dispatch({ type: 'GET_PARTNERS', payload: result.data });
    } catch (err) {
      throw err;
    }
  };

  return (
    <AdminUserContext.Provider
      value={{
        users: state.users,
        schools: state.schools,
        roles: state.roles,
        regions: state.regions,
        municipal: state.municipal,
        userData: state.userData,
        schoolData: state.schoolData,
        responsible_school: state.responsible_school,
        current_reward_user: state.current_reward_user,
        rewardList: state.rewardList,
        federalDistricts: state.federalDistricts,
        applications: state.applications,
        partners: state.partners,
        noRoute,
        setNoRoute,
        getUsers,
        getSchools,
        getRoles,
        getRegions,
        getMunicipal,
        getSchoolById,
        getDataUserCard,
        createNewUser,
        editUser,
        deleteUserCard,
        getDataSchoolCard,
        getResponsiblesForSchool,
        deleteSchoolCard,
        newSchoolCard,
        editSchoolCard,
        onChangeSearchFieldInn,
        getMuinicipalOktmo,
        getExcel,
        getExcelAll,
        getExcelSchool,
        rewardHistory,
        institutionsByRegion,
        rejectInstituteHandler,
        confirmInstituteHandler,
        getRewardLisrt,
        getFederalDistricts,
        addReward,
        deleteReward,
        fetchUploadVerification,
        getApplications,
        getPartners,
        dispatch,
      }}
    >
      {children}
    </AdminUserContext.Provider>
  );
};

export default AdminUserState;
