import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { localStorage } from '@vkontakte/vkjs';

export const darkModeSlice = createSlice({
  name: 'darkMode',
  initialState: {
    darkMode: !!+localStorage.getItem('darkMode'),
  },
  reducers: {
    setDarkMode: (state, action: PayloadAction<boolean>) => {
      state.darkMode = action.payload;

      if (action.payload) {
        document.querySelector('html').classList.add('dark');
        localStorage.setItem('darkMode', '1');
        console.log(localStorage.getItem('darkMode'));
      }

      if (!action.payload) {
        document.querySelector('html').classList.remove('dark');
        localStorage.setItem('darkMode', '0');
        console.log(localStorage.getItem('darkMode'));
      }
    },
  },
});

export const { setDarkMode } = darkModeSlice.actions;
