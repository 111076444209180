import React, { useEffect } from 'react';
import { useProfile } from 'hooks/useProfile';
import { AUTH_STATUS_TYPES } from '@/app/CONSTANTS';
import { doRequest } from 'hooks/doRequest';
import { instance } from 'api/instance';
import { useNavigate, useLocation } from 'react-router-dom';
import { GlobalLoader } from '@/shared/ui/GlobalLoader';

const PreAccelerationAuth = () => {
  const { isAuth } = useProfile();
  const navigate = useNavigate();
  const location = useLocation();

  const _searchParams = new URLSearchParams(location.search);
  const redirectUrl = _searchParams.get('redirect_url');

  useEffect(async () => {
    if (isAuth === AUTH_STATUS_TYPES.AUTHORIZED) {
      if (redirectUrl) {
        const _redirectSearchParams = new URLSearchParams();
        _redirectSearchParams.append('redirect_url', redirectUrl);

        const { data, success } = await doRequest(() => {
          return instance.post(`/api/preinc/auth?${_redirectSearchParams}`);
        });

        if (success) {
          const { url } = data.data;
          window.location = url;
        }
      }
    } else {
      navigate('/auth/signin');
    }
  }, [isAuth, redirectUrl]);

  return (
    <div>
      <GlobalLoader />
    </div>
  );
};

export default PreAccelerationAuth;
