import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PartnerEvent } from '_types/PartnerEvent';

export type SelectedEventState = PartnerEvent | null;
export type ConfirmationDialogOpen = boolean;

type PartnerEventsPageState = {
  confirmationDialogOpen: ConfirmationDialogOpen;
  selectedEvent: SelectedEventState;
};

const initialState: PartnerEventsPageState = {
  confirmationDialogOpen: false,
  selectedEvent: null,
};

const slice = createSlice({
  initialState,
  name: 'events',
  reducers: {
    setConfirmationDialogOpen: (
      state: PartnerEventsPageState,
      action: PayloadAction<ConfirmationDialogOpen>,
    ): void => {
      state.confirmationDialogOpen = action.payload;
    },
    setSelectedEvent: (
      state: PartnerEventsPageState,
      action: PayloadAction<SelectedEventState>,
    ): void => {
      state.selectedEvent = action.payload;
    },
  },
});

export type PartnerEventsPageActions = typeof slice.actions;
export const actions: PartnerEventsPageActions = slice.actions;
const reducer = slice.reducer;
export default reducer;
