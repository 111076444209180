export const Size = {
  Default: 'default',
  Medium: 'medium',
  Large: 'large',
} as const;

export type Size = (typeof Size)[keyof typeof Size];

export const Color = {
  BlackWhite: 'bw',
  WhiteBlack: 'wb',
  DarkBlueSkyBlue: 'dbsb',
} as const;

export type Color = (typeof Color)[keyof typeof Color];

export type Config = {
  fontSize: Size;
  color: Color;
  hideImages: boolean;
  voice: boolean;
  letterSpacing: Size;
  lineHeight: Size;
};

export type ContextValue = {
  isActive: boolean;
  activate: () => void;
  deactivate: () => void;
  toggle: () => void;
  config: Config;
  setConfig: SetConfig;
};

type DefineConfig<Key extends keyof Config | ''> = Key extends keyof Config ? Config[Key] : Config;

export type SetConfig = <Key extends keyof Config | '', ConfigType extends DefineConfig<Key>>(
  key: Key,
  config: ConfigType,
) => void;
