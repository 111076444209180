const { makeStyles } = require('@material-ui/core');

export const useAuthStyles = makeStyles((theme) => ({
  authContainer: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    flex: 1,
  },
}));
