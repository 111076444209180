import classes from './index.module.scss';
import classNames from 'classnames';

export const Loader = ({ small = false }: { small?: boolean }) => {
  return (
    <div className={classNames(classes.spinner, { [classes.spinnerSmall]: small })}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export const ScreenLoader = ({ fullScreen = false }: { fullScreen?: boolean }) => {
  return (
    <div
      className={classNames('w-full h-full flex items-center justify-center', {
        'w-[100vw] h-[100vh] fixed left-0 top-0': fullScreen,
      })}
    >
      <Loader />
    </div>
  );
};
