import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { Connect } from '@vkontakte/superappkit';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import Button, { ButtonProps } from '@/shared/ui/Button/Button';
import { ReactComponent as VkIcon } from 'images/vk_round_icon.svg';
import { useAuthorizeVkUserMutation } from '@/_services/authApi';
import { VK_API_VERSION } from '@/app/CONSTANTS';
import { authenticateWithToken } from '@/_redux/appSlice';
import { setRegisterSliceState } from '_redux/register/register.slice';
import classes from './VKAuthButton.module.scss';
import { Loader } from '@/shared/ui/ScreenLoader';

const VKAuthButton = (props: ButtonProps) => {
  const { className, children = 'Войти через VK ID', ...buttonProps } = props;
  const [triggerAuth] = useAuthorizeVkUserMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const urlParams = new URLSearchParams(search);
  const isSigninPage = pathname.includes('signin');
  const vkAuthResult = JSON.parse(urlParams.get('payload'));

  const handleClick = async () => {
    try {
      Connect.redirectAuth({
        url: `${window.location.origin}/auth/signin`,
      });
    } catch (error) {
      toast('Произошла ошибка авторизации', { type: 'error' });
    }
  };

  useEffect(() => {
    const login = async () => {
      if (!vkAuthResult?.token || !isSigninPage) {
        return;
      }

      try {
        const authResult = await triggerAuth({
          silentToken: vkAuthResult.token,
          uuid: vkAuthResult.uuid,
          v: VK_API_VERSION,
        }).unwrap();

        if (authResult.token) {
          dispatch(
            authenticateWithToken({
              token: authResult.token,
            }),
          );

          navigate('/lk/profile');
        } else if (authResult.code === 1) {
          dispatch(
            setRegisterSliceState({
              vkIdKey: authResult.vk_id_key,
            }),
          );

          navigate('/auth/vk-not-found');
        }
      } catch {
        toast('Произошла ошибка авторизации', { type: 'error' });
      }
    };

    const timeout = setTimeout(login, 1500);

    return () => clearTimeout(timeout);
  }, []);

  if (isSigninPage && vkAuthResult?.token) {
    return (
      <div className={classes.loader}>
        <Loader small />
      </div>
    );
  }

  return (
    <Button
      className={clsx(className, classes.root)}
      fullWidth
      startIcon={<VkIcon />}
      onClick={handleClick}
      color='blue'
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

export default VKAuthButton;
