import * as yup from 'yup';
import htmlToDraft from 'html-to-draftjs';
import { ContentState, EditorState } from 'draft-js';
import moment, { Moment } from 'moment';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { MaskedInputDateFormat } from '@/shared/ui/TextField/_constants/MaskedInputDateFormat';
import { getDeclensionString } from '@/shared/utils/getDeclensionString';

type DateStringMinOptions = {
  inclusive: boolean;
};

type DateStringMaxOptions = DateStringMinOptions;

declare module 'yup' {
  export class StringSchema {
    editorMax: (max: number) => this;
    editorRequired: () => this;
    dateString: () => this;
    dateStringMax: (dateMax: string | Moment, options?: DateStringMaxOptions) => this;
    dateStringMin: (dateMin: string | Moment, options?: DateStringMinOptions) => this;
    timeString: () => this;
    phoneString: () => this;
  }

  export class ArraySchema<T> {
    fileSizeMax: (maxFileSizeMB: number) => this;
    file: (msg?: string) => this;
  }
}

export const YUP_REQUIRED_LABEL = (f) => {
  return `Поле необходимо заполнить`;
};

const notType = (_ref) => {
  const { type } = _ref;

  switch (type) {
    case 'number':
      return 'Значение должно быть числом';
    case 'string':
      return 'Значение должно быть строкой';
    default:
      return 'Неверный тип данных';
  }
};

yup.setLocale({
  mixed: {
    notType,
    required: (f) => YUP_REQUIRED_LABEL(f),
  },
  string: {
    min: ({ min }) => `Минимальная длина - ${min} ${getDeclensionString('символ', min)}`,
    max: ({ max }) => `Максимальная длина - ${max} ${getDeclensionString('символ', max)}`,
    length: ({ length }) => `Длина поля должна равнятся ${length}`,
    email: 'Введите корректный email',
    url: 'Введите корректную ссылку',
  },
  array: {
    min: ({ min }) =>
      `Необходимо выбрать как минимум ${min} ${getDeclensionString('элемент', min)}`,
    max: ({ max }) => `Необходимо выбрать до ${max} ${getDeclensionString('элемент', max)}`,
  },
  number: {
    min: ({ min }) => `Число должно быть не меньше ${min}`,
    max: ({ max }) => `Число должно быть не больше ${max}`,
  },
});

yup.addMethod(yup.string, 'editorMax', function (length) {
  return this.test('editorMax', `Максимальная длина - ${length} символов`, function (value) {
    if (!value) return true;
    const { contentBlocks, entityMap } = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    const plainText = editorState.getCurrentContent().getPlainText();

    return plainText.length <= length;
  });
});

yup.addMethod(yup.string, 'editorRequired', function () {
  return this.test('editorMax', `Поле необходимо заполнить`, function (value) {
    if (!value) return false;
    const { contentBlocks, entityMap } = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    const plainText = editorState.getCurrentContent().getPlainText();

    return Boolean(plainText.length);
  });
});

yup.addMethod(yup.string, 'dateString', function () {
  return this.test('dateString', `Неверный формат даты`, function (value) {
    if (!value) return true;
    return moment(value, MaskedInputDateFormat).isValid();
  });
});

yup.addMethod(
  yup.string,
  'dateStringMin',
  function (dateMin: string | Moment, options: DateStringMinOptions) {
    const { inclusive } = options || {};

    const dateMinMoment = moment(dateMin, MaskedInputDateFormat);
    return this.test(
      'dateString',
      `Дата должна быть не меньше ${dateMinMoment.format(MaskedInputDateFormat)}`,
      function (value) {
        if (inclusive) {
          return moment(value, MaskedInputDateFormat).isSameOrAfter(dateMinMoment, 'date');
        }

        if (!inclusive) {
          return moment(value, MaskedInputDateFormat).isAfter(dateMinMoment, 'date');
        }
      },
    );
  },
);

yup.addMethod(
  yup.string,
  'dateStringMax',
  function (dateMax: string | Moment, options: DateStringMaxOptions) {
    const { inclusive } = options || {};

    const dateMaxMoment = moment(dateMax, MaskedInputDateFormat);
    return this.test(
      'dateString',
      `Дата должна быть не больше ${dateMaxMoment.format(MaskedInputDateFormat)}`,
      function (value) {
        if (inclusive) {
          return moment(value, MaskedInputDateFormat).isSameOrBefore(dateMaxMoment, 'date');
        }

        if (!inclusive) {
          return moment(value, MaskedInputDateFormat).isBefore(dateMaxMoment, 'date');
        }
      },
    );
  },
);

yup.addMethod(yup.string, 'phoneString', function () {
  return this.test('phoneString', 'Номер телефона указан неверно', function (value) {
    if (!value) return true;

    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      const phoneNumber = phoneUtil.parse(value);
      return phoneUtil.isValidNumber(phoneNumber);
    } catch (e) {
      return false;
    }
  });
});

yup.addMethod(yup.mixed, 'fileSizeMax', function (maxFileSizeMB: number, msg?: string) {
  const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024;

  return this.test(
    'fileSizeMax',
    msg || `Максимальный размер файла - ${maxFileSizeMB}Мб`,
    function (file: File) {
      if (!file) return true;

      if (file?.size > maxFileSizeBytes) {
        return false;
      }

      return true;
    },
  );
});

yup.addMethod(yup.array, 'fileSizeMax', function (maxFileSizeMB: number) {
  const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024;

  return this.test(
    'fileSizeMax',
    `Максимальный размер файла - ${maxFileSizeMB}Мб`,
    function (files?: Array<File>) {
      if (!files) return true;

      for (let file of files) {
        if (file.size > maxFileSizeBytes) {
          return false;
        }
      }

      return true;
    },
  );
});

yup.addMethod(yup.array, 'file', function (msg?: string) {
  return this.test('file', msg || '', function (files: File[]) {
    if (!files) return true;

    for (let file of files) {
      if (!(file instanceof File)) {
        return false;
      }
    }

    return true;
  });
});

yup.addMethod(yup.string, 'timeString', function () {
  return this.test('timeString', 'Неверный формат времени', function (time = '') {
    const [hh, mm] = time.split(':');

    if (!!hh && !!mm && +hh <= 23 && +mm <= 59) {
      return true;
    }
  });
});
