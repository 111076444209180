export const parseResponseErrors = (error): string[] => {
  const aggregatedErrors = [];

  if (typeof error === 'string') {
    aggregatedErrors.push(error);
  } else if (typeof error === 'object') {
    if (Array.isArray(error)) {
      for (let errorItem of error) {
        aggregatedErrors.push(...parseResponseErrors(errorItem));
      }
    } else {
      for (let errorKey in error) {
        aggregatedErrors.push(...parseResponseErrors(error[errorKey]));
      }
    }
  }

  return aggregatedErrors;
};
