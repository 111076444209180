import { useEffect } from 'react';
import { setAppState } from '../_redux/appSlice';
import { AuthenticationStatus } from '../_constants/authentication-status';
import { useProfileQuery } from '../_services/usersApi/usersApi';
import { AppDispatch, RootState } from '../_redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { baseApi } from '_services/baseApi';

/** Производит аутентификацию клиента */
export const useAuthentication = () => {
  const { authenticationStatus } = useSelector((rootState: RootState) => rootState.app);
  const profileQuery = useProfileQuery();
  const dispatch: AppDispatch = useDispatch();

  // useEffect(() => {
  //   if (authenticationStatus === AuthenticationStatus.AUTHORIZED) {
  //     profileQuery.refetch();
  //   }
  // }, [authenticationStatus]);

  useEffect(() => {
    if (authenticationStatus === AuthenticationStatus.ANONYMOUS) {
      dispatch(baseApi.util.resetApiState());
    }
  }, [authenticationStatus]);

  useEffect(() => {
    if (profileQuery.data) {
      dispatch(
        setAppState({
          authenticationStatus: AuthenticationStatus.AUTHORIZED,
        }),
      );
    }

    if (!profileQuery.isSuccess && !profileQuery.isLoading) {
      dispatch(
        setAppState({
          authenticationStatus: AuthenticationStatus.ANONYMOUS,
        }),
      );
    }
  }, [profileQuery.data, profileQuery.isSuccess, profileQuery.isLoading]);
};
