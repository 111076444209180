export const getDeclensionString = (str: string, count: number) => {
  if (count === 1) {
    return str;
  }

  if (count > 1 && count < 5) {
    return `${str}а`;
  }

  return `${str}ов`;
};
