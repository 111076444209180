import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { baseApi } from '_services/baseApi';
import { setupListeners } from '@reduxjs/toolkit/query';
import appSlice from '_redux/appSlice';
import { RegisterSlice } from './register/register.slice';
import events from '_redux/partnerEventsPage/partnerEventsPage.slice';
import { adminSlice } from './admin/admin.slice';
import { darkModeSlice } from './darkMode/darkMode.slice';
import { cheatsSlice } from './cheats/cheats.slice';
import { forumSlice } from './forum/forum.slice';
import { drugoeDeloSlice } from './drugoeDelo/drugoeDelo.slice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { portfolioEdit } from '@/features/portfolioEdit/model';

const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  register: RegisterSlice.reducer,
  admin: adminSlice.reducer,
  app: appSlice,
  events,
  darkMode: darkModeSlice.reducer,
  cheats: cheatsSlice.reducer,
  forum: forumSlice.reducer,
  drugoeDelo: drugoeDeloSlice.reducer,
  portfolioEdit,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(baseApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
