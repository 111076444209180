import { Config } from '@vkontakte/superappkit';

const VK_ID_APP = process.env.VK_ID_APP;

Config.init({
  appId: Number(VK_ID_APP),
  // loginDomain: 'login.vk.ru',
  // oauthDomain: 'oauth.vk.ru',
  // connectDomain: 'id.vk.ru',
});
