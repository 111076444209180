import { AuthenticationStatus } from '_constants/authentication-status';

export const authReducer = (state, action) => {
  switch (action.type) {
    case 'SET_STATE':
      return {
        ...state,
        ...action.payload,
      };
    case 'LOGINED':
      return {
        ...state,
        ...action.payload,
        isAuth: AuthenticationStatus.AUTHORIZED,
      };

    case 'LOGIN_ERROR':
      return {
        ...state,
        isAuth: AuthenticationStatus.ANONYMOUS,
      };

    case 'SET_SERTIFICATE':
      return {
        ...state,
        certificate: { ...action.payload },
      };

    case 'LOGOUT':
      try {
        localStorage.removeItem('JWT');
      } catch {
        console.log('error');
      }
      return {
        ...action.payload,
        isAuth: 0,
      };

    case 'UPDATE_USER':
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
