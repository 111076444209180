import { responsiveFontSizes } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';

export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: '#485DC9',
        light: '#485DC9',
      },
      secondary: {
        main: '#EF4444',
        light: '#EF4444',
      },
      error: {
        light: '#FFDFDF',
        main: '#F15053',
        dark: '#5E0809',
      },
      text: {
        primary: '#1B1B1B',
      },
      blackGray: {
        main: '#3F3F3F',
      },
      brandGray: {
        main: '#DBDFE8',
      },
      brandGreen: {
        main: '#39DEC8',
      },
      brandRed: {
        main: '#F15053',
        contrastText: '#fff',
      },
      brandBlue: {
        main: '#9CADE5',
        alternative: '#3a98ef',
      },
    },
    brandColors: {
      blackGray: {
        main: '#3F3F3F',
      },
      brandGray: {
        main: '#DBDFE8',
      },
      brandGreen: {
        main: '#39DEC8',
      },
      brandRed: {
        main: '#F15053',
      },
      brandBlue: {
        main: '#9CADE5',
        alternative: '#3a98ef',
      },
    },
    main: '#272c5B',
    blue: '#0073AC',
    black: '#1B1B1B',
    pink: '#FEBDAC',
    light: '#fff',
    ltblue: '#D9FFFF',
    ltred: '#FF7271',

    typography: {
      fontFamily: [
        'Inter',
        'Proxima Nova Rg',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe shared"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe shared Emoji"',
        '"Segoe shared Symbol"',
      ].join(','),
      allVariants: {
        color: '#3b3f43',
      },
    },
  }),
);

export const xs = theme.breakpoints.down('sm');
