import { useVisuallyImpariedMode } from '@/features/visuallyImpairedMode/provider';
import { createPortal } from 'react-dom';
import classes from './VisuallyImpairedPanel.module.scss';
import { VisuallyImpairedFontSizes } from '../VisuallyImpairedFontSizes/VisuallyImpairedFontSizes';
import { VisuallyImpairedColors } from '../VisuallyImpairedColors/VisuallyImpairedColors';
import {
  ImageOutlined,
  NotInterested,
  SettingsOutlined,
  VisibilityOutlined,
  VolumeDownOutlined,
} from '@material-ui/icons';
import classNames from 'classnames';
import { useBoolean } from 'hooks/useBoolean';
import { Collapse } from '@material-ui/core';
import { Size } from '@/features/visuallyImpairedMode/types';
import { DEFAULT_CONFIG } from '@/features/visuallyImpairedMode/model';
import { LETTER_SPACING_TITLES, LINE_HEIGHT_TITLES } from '../../constants';

export const VisuallyImpairedPanel = () => {
  const { isActive, config, setConfig, deactivate } = useVisuallyImpariedMode();
  const collapse = useBoolean();

  const renderChildren = () => {
    return (
      <div className={classes.panelWrapper}>
        <div className={classes.panel}>
          <div className={classes.panelBlock}>
            <VisuallyImpairedFontSizes />
          </div>
          <div className={classes.panelBlock}>
            <VisuallyImpairedColors />
          </div>
          <div className={classes.panelBlock}>
            <button
              className={classNames(classes.btn, 'vim-svg-btn')}
              onClick={() => {
                setConfig('hideImages', !config.hideImages);
              }}
            >
              {config.hideImages ? <NotInterested /> : <ImageOutlined />}
            </button>
          </div>
          <div className={classes.panelBlock}>
            <button
              className={classNames(classes.btn, 'vim-svg-btn')}
              onClick={() => setConfig('voice', !config.voice)}
              style={{
                borderBottomWidth: config.voice ? 3 : 1,
              }}
            >
              <VolumeDownOutlined />
            </button>
          </div>
          <div className={classes.panelBlock}>
            <button className={classNames(classes.btn, 'vim-svg-btn')} onClick={deactivate}>
              <VisibilityOutlined />
            </button>
          </div>
          <div className={classes.panelBlock}>
            <button className={classNames(classes.btn, 'vim-svg-btn')} onClick={collapse.toggle}>
              <SettingsOutlined />
            </button>
          </div>
        </div>
        <Collapse in={collapse.state}>
          <div className={classes.collapseContent}>
            <div>
              <span className='vim-panel-txt'>Интервал между буквами:</span>{' '}
              {Object.values(Size).map((size) => {
                return (
                  <button
                    key={size}
                    className='vim-panel-btn'
                    style={{
                      borderBottomWidth: config.letterSpacing === size ? 3 : 1,
                    }}
                    onClick={() => setConfig('letterSpacing', size)}
                  >
                    {LETTER_SPACING_TITLES[size]}
                  </button>
                );
              })}
            </div>
            <div className='mt-4'>
              <span className='vim-panel-txt'>Интервал между строками:</span>{' '}
              {Object.values(Size).map((size) => {
                return (
                  <button
                    key={size}
                    className='vim-panel-btn'
                    style={{
                      borderBottomWidth: config.lineHeight === size ? 3 : 1,
                    }}
                    onClick={() => setConfig('lineHeight', size)}
                  >
                    {LINE_HEIGHT_TITLES[size]}
                  </button>
                );
              })}
            </div>
            <div className='mt-8 flex justify-between flex-wrap'>
              <button className='vim-panel-btn' onClick={() => setConfig('', DEFAULT_CONFIG)}>
                Параметры по умолчанию
              </button>
              <button className='vim-panel-btn' onClick={collapse.setFalse}>
                Закрыть
              </button>
            </div>
          </div>
        </Collapse>
      </div>
    );
  };

  return isActive && createPortal(renderChildren(), document.body);
};
