import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  prefilledUserEventId: null,
  prefilledEventFormFields: null,
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setAdminSliceState: (state, action: PayloadAction<Partial<typeof initialState>>) => {
      state = { ...state, ...action.payload };
      return state;
    },
  },
});

export const { setAdminSliceState } = adminSlice.actions;
