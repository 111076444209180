import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const cheatsSlice = createSlice({
  name: 'cheats',
  initialState: {
    currentCheat: '',
  },
  reducers: {
    setCheatCode: (state, action: PayloadAction<string>) => {
      state.currentCheat = action.payload;
    },
  },
});

export const { setCheatCode } = cheatsSlice.actions;
