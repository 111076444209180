import React from 'react';
import moment from 'moment';
import 'moment/locale/ru';

export const VK_API_VERSION = '5.131';
export const TH_VK_LINK = 'https://vk.com/tvoyhodonline';
export const TH_INSTAGRAM_LINK = 'https://www.instagram.com/tvoy.xod/';
export const TH_ZEN_LINK = 'https://zen.yandex.ru/id/6228d557b2ff024222b0a625';
export const TH_TELEGRAM_LINK = 'https://t.me/tvoyhodSpeak';
/** Количество инвайтов, необходимых для получения статуса амбассадора */
export const AMBASSADOR_INVITE_REQUIREMENT = 10;

export const FINISH_DATE_ITER = '2021/08/08 23:59:00';
export const DistrictStageTrackActPresentationExpireDate = moment('2022-10-29T23:59:59+03');
export const DistrictStageTrackDevPresentationExpireDate = moment('2022-10-29T23:59:59+03');
export const TrackCreateSubmitFormExpireDate = moment('2022-10-21T23:59:59+03');
export const SEASON3_START_DATE = moment('2023-01-25T00:00:00+03');

export const TrackSurveyExpireDate = moment('2022-10-31T10:00+03');

export const UNIVERSITY_TYPES = {
  CONFIRMED: 1,
  CONFIRMATION_REQUIRED: 2,
  NONE: 3,
};

export const TEST_ONLINE = [
  {
    title: 'Твой выбор',
    generalResultTitle:
      '«Твой выбор» – ваши ведущие интересы и предпочтения в профессиональной деятельности',
    name: '20210519-132201-3842',
    time: '10 минут',
    isNew: true,
    descriptions: (
      <div>
        <p>
          Это финишная прямая, дружок! Пройди последний тест — узнаешь, куда Распределительная Шляпа
          «Твоего Хода» тебя зачислила (только не Слизерин, только не Слизерин 🤞). Но, конечно, ты
          можешь выбрать свой путь. Мы только даем рекомендации.
        </p>
        <p>
          В заключительном тесте 4 вопроса. В каждом можно выбрать не более 6 вариантов ответа.
          Собирайся с силами и отправляйся узнавать свое направление 😉
        </p>
      </div>
    ),
    generalResultDescription: (
      <div>
        <p>
          Этот тест поможет понять, какие из вызовов «Твоего Хода» тебя драйвят. Ниже — описания
          вызовов, которые подходят тебе больше всего. Если описаний нет, значит, пока тебе нечего
          порекомендовать. Скорее всего, ты выбрал слишком редкие критерии в необычных или
          противоречивых сочетаниях.
        </p>
        <p>
          Но ничего страшного! Ты можешь сделать выбор сам и попробовать себя в активности, которая
          тебе нравится больше других. Реальный опыт покажет, насколько она тебе подходит. Читай про
          вызовы в полном отчете по тестированию или на платформе конкурса.
        </p>
      </div>
    ),
  },
  {
    title: 'Твой вектор',
    generalResultTitle: '«Твой вектор» – ваши ведущие типы мышления',
    name: '20210511-153020-9624',
    time: '10 минут',
    isNew: true,
    descriptions: (
      <div>
        <p>
          Какая-то профессия кажется тебе интересной, но ты не уверен в своем выборе на 100%? И на
          этот случай у нас есть тест 😉 Пройди его, чтобы узнать особенности своего мышления,
          профессиональные склонности и предпочтения. С помощью результатов ты поймешь, понравится
          ли тебе эта специальность на самом деле.
        </p>
        <p>
          В тесте 25 вопросов. В каждом можно выбрать не более 2 вариантов ответа. Постарайся как
          можно реже нажимать «Ничего из предложенного не подходит», иначе результаты теста тебе ни
          о чем не скажут.
        </p>
        <p>Примерное время тестирования — 10-15 минут. Приступаем?</p>
      </div>
    ),
    generalResultDescription: (
      <div>
        <p>
          Этот тест оценивал твой тип мышления. В смысле, не логические способности, а скорее твой
          стиль — то, как ты думаешь и действуешь, на чем фокусируешься и что замечаешь вокруг:
          логику, музыку, законы природы или что-то другое.
        </p>
        <p>
          Ниже находятся описания типов мышления, которые у тебя выражены больше всего. Если
          описания нет — значит, у тебя нет явно выраженного типа, ты примерно поровну пользуешься
          всеми, в каждом есть что-то для тебя интересное. И это нормально!
        </p>
        <p>
          Но при желании любой тип мышления можно развить. Например, если хочешь развить природный
          тип — собирай коллекции ракушек, изучай классификации животных и растений, запоминай
          названия рек и городов, учись определять созвездия.
        </p>
      </div>
    ),
  },
  {
    title: 'Твоя мотивация',
    generalResultTitle: '«Твоя мотивация» – ваша ведущая мотивация и тип карьеры',
    name: '20210423-212929-4580',
    time: '10 минут',
    descriptions: (
      <div>
        <p>
          Чтобы найти работу мечты, бесполезно опрашивать однокурсников и слушать советы родителей.
          На самом деле, ты сам знаешь ответы на все вопросы — главное правильно спросить. Этот тест
          поможет определить твои предпочтения, стремления и интересы, которые важны при поиске
          работы. Пройди его, и узнаешь, от каких результатов труда ты по-настоящему кайфуешь. Это
          поможет понять, к каким вакансиям присматриваться 💪
        </p>
        <p>
          В тесте 56 вопросов с 3 вариантами ответа. Тебе нужно выбрать один из них. А если
          понимаешь, что в заданной ситуации можешь повести себя по-разному в зависимости
          от обстоятельств, отвечай исходя из того, что тебе интересно прямо сейчас.
        </p>
        <p>
          Время на выполнение не ограничено. Но постарайся не сидеть подолгу над одним заданием:
          скорее всего, первый ответ, который придет в голову — самый верный. В среднем тест
          занимает 10-15 минут.
        </p>
      </div>
    ),
    generalResultDescription: (
      <div>
        <p>
          Этот тест определял важные для тебя моменты в работе, чтобы ты нашел подходящий тип
          карьеры. Ниже увидишь описания мотивов, которые отличают тебя от большинства.
        </p>
        <p>
          Если таких описаний нет, значит, ты во всем предпочитаешь золотую середину. Тебе в равной
          степени важны и комфорт, и престиж, и возможность общаться, творить или преодолевать
          трудности и т. д. Баланс мотивов — это классно: ты можешь адаптироваться к разным условиям
          и находить общий язык с разными людьми.
        </p>
        <p>
          Этот баланс может меняться: со временем ты определишь ключевые факторы и откажешься от
          второстепенных. Просто пока сложно говорить о ярко выраженной мотивации, которая толкает
          тебя вперед, — и это нормально!
        </p>
      </div>
    ),
  },
  {
    title: 'Твои роли',
    generalResultTitle: '«Твои роли» – ваши ведущие карьерные интересы и предпочтения',
    name: '20210427-172238-3775',
    time: '10 минут',
    descriptions: (
      <div>
        <p>
          Этот тест, конечно, не Распределяющая Шляпа, но вполне может с ней потягаться. Только
          вместо факультетов ищем подходящие специальности. Правда, мысли читать мы не умеем,
          поэтому придется отвечать на вопросы 🤓
        </p>
        <p>
          Тест состоит из 14 вопросов. В каждом из них — какая-то ситуация и 8 вариантов поведения.
          Найди сценарий, который кажется тебе наиболее привлекательным. Можно выбрать до 3
          вариантов. Нет правильных и неправильных ответов — важно понять, что тебе интересно прямо
          сейчас или в недалеком будущем.
        </p>
        <p>Тест займет около 10 минут. На старт, поехали 💨</p>
      </div>
    ),
    generalResultDescription: (
      <div>
        <p>
          Этот тест определял твои карьерные интересы и склонности к ролям, которые встречаются в
          профессиональной деятельности и при работе в команде. Ниже чекай описания ролей, которые
          идеально тебе подходят.
        </p>
        <p>
          Если какой-то роли нет, значит, она тебе не очень интересна. А если совсем никаких
          описаний нет? Ничего страшного — наверное, ты просто устал и хочешь отдохнуть 😎 Не
          расстраивайся, все в твоих руках. Ты обязательно найдешь направление по душе!
        </p>
      </div>
    ),
  },
  {
    title: 'Твоя логика',
    generalResultTitle: '«Твоя логика» – ваши выраженные логические способности',
    name: 'tvoia-logika',
    descriptions: (
      <p>
        Ну что, готов узнать особенности своего мозга? Надевать футуристичную шапочку с проводами не
        придется — просто пройди тест на выявление логических способностей. Мы приготовили 3 блока с
        11-12 вопросами в каждом. Всего 35 заданий. В задании — 4 варианта ответов, тебе нужно найти
        правильный (или угадать 😏).
        <br />
        <br />
        Обрати внимание: время на выполнение блока ограничено. На каждое задание отводится до 90
        секунд, на каждый блок — от 6 до 15 минут. Так что чекай счетчики прошедшего времени и
        выполненных заданий. Круто, если показания идут вровень. Переходи на первую космическую
        скорость, если поймешь, что заданий много, а времени мало.
        <br />
        <br />
        Не парься, если не получится выполнить все задания. Мы специально сделали так, чтобы никто
        не справился на 100% (но ты можешь бросить нам вызов!). Если тормозишь на каком-то задании,
        тыкни в наиболее вероятный ответ. Есть 25% вероятности, что не ошибешься. Не волнуйся и
        погнали! Успехов, друг 🍀
      </p>
    ),
    generalResultDescription: (
      <div>
        <p>
          Этот тест определял твои карьерные интересы и склонности к ролям, которые встречаются в
          профессиональной деятельности и при работе в команде. Ниже чекай описания ролей, которые
          идеально тебе подходят.
        </p>
        <p>
          Если какой-то роли нет, значит, она тебе не очень интересна. А если совсем никаких
          описаний нет? Ничего страшного — наверное, ты просто устал и хочешь отдохнуть 😎 Не
          расстраивайся, все в твоих руках. Ты обязательно найдешь направление по душе!
        </p>
      </div>
    ),
  },
  {
    title: 'Твоя позиция',
    generalResultTitle: '«Твоя позиция» – ваши ведущие ценности и установки',
    name: 'tvoia-pozitsia-student',
    time: '7-10 минут',
    descriptions: (
      <p>
        Здравствуй!
        <br />
        <br />
        Приглашаем тебя пройти тест, который оценит особенности твоего мышления, твои
        профессиональные склонности и предпочтения.
        <br />
        <br />
        Всего тебе предстоит ответить на 50 вопросов, в каждом можно выбрать не более 2-х подходящих
        тебе вариантов ответа.
        <br />
        <br />
        Примерное время тестирования &ndash; 10-15 минут.
        <br />
        <br />
        Важно: старайся как можно реже выбирать вариант "Ничего из предложенного не подходит". Даже
        если описанная ситуация кажется тебе непривычной, все же постарайся найти среди вариантов
        более близкие тебе, иначе результат теста окажется малоинформативным.
        <br />
        <br />А теперь приготовься к работе, и начинаем!
      </p>
    ),
    generalResultDescription: (
      <div>
        <p>
          Ты к гадалке не ходил, но точно сейчас узнаешь, почему твоя жизнь складывается так, а не
          иначе — ведь ты прошел тест на определение своей позиции ✌ Ниже читай об установках и
          ценностях, которые у тебя выражены ярче, чем у остальных.
        </p>
        <p>
          Если ты не видишь описания по каким-то шкалам, значит, сейчас твои показатели схожи с
          показателями большинства. Это может означать, что ты не любитель крайностей. Ты не
          испытываешь судьбу, но и не сидишь в сторонке. Не гонишься за новизной, но открыт к
          переменам. Не рвешься стать жителем другой страны, но и не считаешь себя ярым патриотом.
          Присмотрись к себе: что движет тобой чаще? Проанализируй свои поступки и решения.
        </p>
      </div>
    ),
  },
  {
    title: 'Твой код',
    generalResultTitle: '«Твой код» – ваши яркие личностные качества',
    name: 'tvoi-kod-student',
    time: '10-15 минут',
    descriptions: (
      <>
        <p>
          Перед тобой тест, который позволит выяснить некоторые особенности твоего характера,
          привычек и склонностей. Данная методика позволит определить твой склад характера и
          темперамента. Здесь нет "правильных и неправильных" ответов, есть ответы, которые отражают
          или не отражают тебя как человека. Поэтому выбирай варианты, которые покажутся наиболее
          близкими, наиболее похожими на тебя. Чем точнее ты сможешь подобрать эти ответы, тем
          полезнее будет результат тестирования. При этом, старайся долго не задумываться и выбирать
          тот ответ, который первым приходит в голову, так как, обычно, он самый верный.
        </p>
        <p>
          {' '}
          В тесте 74 вопроса. Каждый вопрос предполагает всего 2 варианта ответа, и тебе необходимо
          выбрать один из них. Время работы с блоком не ограничено. Приготовься к работе, соберись с
          мыслями. Успеха!
        </p>
      </>
    ),
    generalResultDescription: (
      <div>
        <p>
          Тест, который ты только что прошел, дает возможность оценить особенности твоего характера.
          Это поможет понять, в каких условиях тебе комфортно жить и работать. Ниже мы расскажем о
          качествах, которые отличают тебя от остальных. Познакомься со своими сильными сторонами!
        </p>
        <p>
          Не переживай, если не найдешь описание каких-либо шкал. Это значит, что ты — универсальный
          боец и можешь приспособиться к разным условиям. Тебе в кайф работать и с людьми, и без
          них. Ты принимаешь решения с опорой и на чувства, и на разум. Полагаешься и на интуицию, и
          на факты. Соблюдаешь порядок в делах, но без фанатизма. Ты не мегалидер, но и не
          прогибаешься под остальных. Это хороший расклад! Продолжай познавать себя — ты обязательно
          найдешь свои сильные стороны.
        </p>
      </div>
    ),
  },
];

export const RESULT_BARS_COLOR_PALETTE = ['#39DEC8', '#F15053', '#9CADE5', '#39DEC8', '#F15053'];

export const TECH_SUP_EMAIL = 'help@tvoyhod.online';
export const TECH_SUP_PHONE = '8 800 505 45 63';

export const MASS_MEDIA_EMAIL = 'press@tvoyhod.online';

/**
 * @deprecated Deprecated. Use `AuthenticationStatus instead`
 */
export const AUTH_STATUS_TYPES = {
  PENDING: 2,
  AUTHORIZED: 1,
  ANONYMOUS: 0,
};

export const ROLE_TYPES = {
  USER: {
    roleId: 1,
  },
  ADMIN: {
    roleId: 4,
  },
  EXPERT: {
    roleId: 11,
  },
  HEAD_EXPERT: {
    roleId: 12,
  },
};

export const PARTICIPATION_TYPES = {
  PLAYER: {
    id: 1,
    name: 'Участник конкурса',
  },
  SPECTATOR: {
    id: 2,
    name: 'Участник экосистемы',
  },
};

export const MEMBERSHIP_TYPES = {
  SOLO: {
    id: 1,
    label: 'Участвую сам',
    name: 'SOLO',
  },
  MEMBER: {
    id: 3,
    label: 'Я - командный игрок',
    name: 'MEMBER',
  },
  LEAD: {
    id: 2,
    label: 'Я - капитан',
    name: 'LEAD',
  },
};

export const TEAM_LEAD_ROLE_ID = 1;

export const ALLOW_APPEAL_ROLES = [MEMBERSHIP_TYPES.LEAD, MEMBERSHIP_TYPES.SOLO];

export const CLOTHING_SIZES = [
  {
    id: 1,
    title: 'XS',
  },
  {
    id: 2,
    title: 'S',
  },
  {
    id: 3,
    title: 'M',
  },
  {
    id: 4,
    title: 'L',
  },
  {
    id: 5,
    title: 'XL',
  },
  {
    id: 6,
    title: 'XXL',
  },
];

export const MAIN_EVENT_TYPES = {
  EVENT2021: {
    id: 1,
  },
  EVENT2022: {
    id: 2,
  },
};
