import i18n, { InitOptions } from 'i18next';
import I18NextHttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const initOptions: InitOptions<unknown> = {
  lng: 'ru',
  fallbackLng: ['ru'],
  ns: ['common'],
  defaultNS: 'common',
  debug: true,
  interpolation: {
    escapeValue: false,
  },
};

i18n.use(I18NextHttpBackend).use(initReactI18next).init(initOptions);

export default i18n;
