import { logout } from '_redux/appSlice';
import { store } from '_redux/store';
import { baseApi } from '_services/baseApi';
import axios from 'axios';

export const isLocalhost =
  window.location.hostname === 'localhost' || window.location.hostname.startsWith('192.168.');
export const apiHost = isLocalhost ? 'https://sttest.bolshajaperemena.ru' : '';

export const instance = axios.create({
  // withCredentials: false,
  // baseURL: '',
  // timeout: 0,
  // headers: {
  //   'Content-Type': 'application/json',
  // },
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      console.log('%c401 ошибка', 'color: #3A4699; font-size: 16px;');
      store.dispatch(logout());
      store.dispatch(baseApi.util.resetApiState());
    }

    if (error?.response?.status === 503) {
      window.location.href = '/';
    }

    return Promise.reject(error);
  },
);

instance.interceptors.request.use(
  function (config) {
    let token = localStorage.getItem('JWT');

    if (token) config.headers.authorization = `Bearer ${token}`;

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);
