import { Size } from './types';

export const VISUALLY_IMPAIRED_MODE_CLASS = 'vim';

export const LETTER_SPACING_TITLES = {
  [Size.Default]: 'Стандартный',
  [Size.Medium]: 'Средний',
  [Size.Large]: 'Большой',
};

export const LINE_HEIGHT_TITLES = {
  [Size.Default]: 'Стандартный',
  [Size.Medium]: 'Полуторный',
  [Size.Large]: 'Двойной',
};
