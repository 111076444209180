import { baseApi } from './baseApi';
import { bool } from 'yup';

export const authApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    register: build.mutation<any, FormData>({
      query: (body) => ({
        url: '/auth/register/confirm',
        method: 'POST',
        body,
      }),
    }),
    checkRegisterCode: build.mutation<any, { email: string; code: string }>({
      query: (body) => ({
        url: '/auth/register/check_code',
        method: 'POST',
        body,
      }),
    }),
    authorizeVkUser: build.mutation<
      { token?: string; email?: string; code?: string | number; vk_id_key?: string },
      { silentToken: string; uuid: string; v: string }
    >({
      query: (params) => ({
        url: '/auth/vk',
        method: 'GET',
        params,
      }),
    }),
    activateVkUser: build.mutation<
      { result: boolean; token: string },
      { email: any; code: any; ambassador?: string }
    >({
      query: (body) => ({
        url: '/auth/vk/activate_user',
        method: 'POST',
        body,
      }),
    }),
    logoutVkUser: build.mutation({
      query: () => ({
        url: '/auth/logout',
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useActivateVkUserMutation,
  useRegisterMutation,
  useAuthorizeVkUserMutation,
  useCheckRegisterCodeMutation,
  useLogoutVkUserMutation,
} = authApi;
