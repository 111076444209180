import React, { useEffect, useState } from 'react';
import { Backdrop, Box } from '@material-ui/core';
import { useCheatCode } from 'hooks/useCheatCode';
import { makeStyles } from '@material-ui/styles';
import ExplosionSrc from './_images/explosion.gif';
import NyanSrc from './_images/nyan.gif';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
  backdropRoot: {
    zIndex: 10000,
  },
  contentRoot: {
    position: 'relative',
  },
  text: {
    fontFamily: 'Seaweed Script',
    fontSize: 176,
    color: 'white',
    opacity: 0,
    animation: `
            $appearance 2s ease forwards, 
            $shakeUp 4s cubic-bezier(1,.29,1,1.02) 1.5s forwards,
            $shake .05s linear 5.5s alternate infinite
        `,
  },
  textExploded: {
    animation: '$textExploded .9s ease',
  },
  img: {
    position: 'fixed',
    width: 600,
    bottom: '50%',
    left: '50%',
    transform: 'translate(-50%, 50%)',
  },
  nyan: {
    height: 180,
    width: 240,
  },
  nyan1: {
    transform: 'rotate(-25deg)',
    animation: '$nyan1 4s linear forwards',
  },
  nyan2: {
    transform: 'scaleX(-1) rotate(25deg)',
    animation: '$nyan2 4s linear forwards',
  },
  '@keyframes appearance': {
    '100%': {
      opacity: 1,
    },
  },
  '@keyframes shakeUp': {
    '10%': {
      transform: 'rotate(15deg)',
    },
    '20%': {
      transform: 'rotate(-15deg)',
    },
    '30%': {
      transform: 'rotate(20deg)',
    },
    '40%': {
      transform: 'rotate(-20deg)',
    },
    '50%': {
      transform: 'rotate(30deg)',
    },
    '60%': {
      transform: 'rotate(-30deg)',
    },
    '70%': {
      transform: 'rotate(35deg)',
    },
    '80%': {
      transform: 'rotate(-35deg)',
    },
  },
  '@keyframes shake': {
    from: {
      transform: 'rotate(-45deg)',
    },
    to: {
      transform: 'rotate(45deg)',
    },
  },
  '@keyframes textExploded': {
    from: {
      opacity: 1,
      filter: 'blur(0)',
    },
    to: {
      opacity: 0,
      fontSize: 250,
      filter: 'blur(20px)',
    },
  },
  '@keyframes nyan1': {
    from: {
      bottom: 0,
      left: 100,
    },
    to: {
      bottom: '30%',
      left: '100%',
    },
  },
  '@keyframes nyan2': {
    from: {
      bottom: '100%',
      left: '100%',
    },
    to: {
      bottom: '30%',
      left: -250,
    },
  },
}));

const TextExplosionEasterEgg = (props) => {
  const { children } = props;
  const text = 'lovedead5';
  const { enabled } = useCheatCode(text);
  const classes = useStyles();

  const [state, setState] = useState({
    explosionVisible: false,
    textExploded: false,
  });

  const { explosionVisible, textExploded } = state;

  const backdropVisible = enabled && (!textExploded || explosionVisible);

  useEffect(() => {
    if (enabled) {
      setTimeout(() => {
        setState((state) => ({ ...state, explosionVisible: true, textExploded: true }));

        setTimeout(
          () =>
            setState((state) => ({
              ...state,
              explosionVisible: false,
            })),
          700,
        );
      }, 6500);
    }
  }, [enabled]);

  return (
    <>
      {children}

      <Backdrop open={backdropVisible} timeout={2400} className={classes.backdropRoot}>
        {backdropVisible && (
          <Box className={classes.contentRoot}>
            <Box className={classnames(classes.text, { [classes.textExploded]: textExploded })}>
              <span>{'Nadya one love'}</span>
            </Box>

            <img src={ExplosionSrc} hidden={!explosionVisible} className={classes.img} />
            <img src={NyanSrc} className={classnames(classes.img, classes.nyan, classes.nyan1)} />
            <img src={NyanSrc} className={classnames(classes.img, classes.nyan, classes.nyan2)} />
          </Box>
        )}
      </Backdrop>
    </>
  );
};

export default TextExplosionEasterEgg;
