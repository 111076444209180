import { useCallback, useEffect, useState } from 'react';
import { Color, Config, ContextValue, SetConfig, Size } from './types';
import { useBoolean } from 'hooks/useBoolean';
import { VISUALLY_IMPAIRED_MODE_CLASS } from './constants';
import {
  getColorClassName,
  getFontSizeClassName,
  getLetterSpacingClassName,
  getLineHeightClassName,
} from './lib';
import { useLocation } from 'react-router-dom';

export const DEFAULT_CONFIG: Config = {
  color: 'bw',
  fontSize: Size.Default,
  hideImages: false,
  letterSpacing: Size.Default,
  lineHeight: Size.Default,
  voice: false,
};

export const useModel = (): ContextValue => {
  const active = useBoolean();
  const [config, _setConfig] = useState(DEFAULT_CONFIG);
  const html = document.documentElement;

  const location = useLocation();

  const setConfig = useCallback<SetConfig>((key, config) => {
    if (key === '') {
      _setConfig(config as Config);
    }
    _setConfig((prevConfig) => ({
      ...prevConfig,
      [key]: config,
    }));
  }, []);

  useEffect(() => {
    if (active.state) {
      html.classList.add(VISUALLY_IMPAIRED_MODE_CLASS);
    } else {
      html.classList.remove(VISUALLY_IMPAIRED_MODE_CLASS);
    }
  }, [active.state]);

  useEffect(() => {
    if (!active.state) {
      return;
    }

    Object.values(Size).forEach((size) => {
      html.classList.remove(getFontSizeClassName(size));
    });
    html.classList.add(getFontSizeClassName(config.fontSize));
  }, [config.fontSize, active.state]);

  useEffect(() => {
    if (!active.state) {
      return;
    }

    Object.values(Color).forEach((color) => {
      html.classList.remove(getColorClassName(color));
    });
    html.classList.add(getColorClassName(config.color));
  }, [config.color, active.state]);

  useEffect(() => {
    if (!active.state) {
      return;
    }

    Object.values(Size).forEach((size) => {
      html.classList.remove(getLetterSpacingClassName(size));
    });
    html.classList.add(getLetterSpacingClassName(config.letterSpacing));
  }, [config.letterSpacing, active.state]);

  useEffect(() => {
    if (!active.state) {
      return;
    }

    Object.values(Size).forEach((size) => {
      html.classList.remove(getLineHeightClassName(size));
    });
    html.classList.add(getLineHeightClassName(config.lineHeight));
  }, [config.lineHeight, active.state]);

  useEffect(() => {
    if (config.hideImages) {
      html.classList.add('vim-hide-images');
    } else {
      html.classList.remove('vim-hide-images');
    }
  }, [config.hideImages]);

  useEffect(() => {
    if ('speechSynthesis' in window) {
      if (config.voice && active.state) {
        const elements = document.querySelectorAll<HTMLParagraphElement>('p,h1,h2,h3,h4,h5,h6,li');

        const listener = (e: MouseEvent) => {
          const target = e.target as HTMLParagraphElement;

          if (speechSynthesis.speaking) {
            speechSynthesis.cancel();
          }

          setTimeout(() => {
            speechSynthesis.speak(new SpeechSynthesisUtterance(target.textContent.trim()));
          }, 100);
        };

        elements.forEach((elem) => {
          elem.addEventListener('mouseover', listener);
        });

        return () => {
          elements.forEach((elem) => {
            elem.removeEventListener('mouseover', listener);
          });
        };
      } else {
        if (speechSynthesis.speaking) {
          speechSynthesis.cancel();
        }
      }
    }
  }, [config.voice, location.pathname, active.state]);

  useEffect(() => {
    if (config.voice) {
      if ('speechSynthesis' in window) {
        const utterance = new SpeechSynthesisUtterance('Включено озвучивание текста');
        speechSynthesis.speak(utterance);
      }
    }
  }, [config.voice]);

  return {
    isActive: active.state,
    activate: active.setTrue,
    deactivate: active.setFalse,
    toggle: active.toggle,
    config,
    setConfig,
  };
};
