import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setRegisterSliceState } from '../_redux/register/register.slice';

/** Сохраняет реферальные данные из url в redux */
export const useReferralLinks = () => {
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    try {
      const ambassadorReferralId = window.atob(params.get('ambassador') || '');
      dispatch(
        setRegisterSliceState({
          ambassadorReferralId,
        }),
      );
    } catch {}
  }, []);
};
