import { useCallback, useState } from 'react';

export const useBoolean = (bool = false) => {
  const [state, set] = useState(bool);

  const toggle = useCallback(() => set((prev) => !prev), []);
  const setFalse = useCallback(() => set(false), []);
  const setTrue = useCallback(() => set(true), []);

  return { state, set, toggle, setFalse, setTrue } as const;
};
