import { useLogActivityMutation } from '../_services/usersApi/usersApi';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../_redux/store';
import { AuthenticationStatus } from '../_constants/authentication-status';

/** Осуществляет мониторинг активности юзера */
export const useUserActivityLogger = () => {
  const [triggerLog] = useLogActivityMutation();
  const { authenticationStatus } = useSelector((root: RootState) => root.app);

  const [state, setState] = useState({
    loggerInterval: null,
  });

  const { loggerInterval } = state;

  useEffect(() => {
    if (authenticationStatus === AuthenticationStatus.AUTHORIZED) {
      triggerLog();

      const loggerInterval = setInterval(() => {
        triggerLog();
      }, 1000 * 60 * 10);

      setState({ ...state, loggerInterval });
    }

    if (authenticationStatus === AuthenticationStatus.ANONYMOUS) {
      clearInterval(loggerInterval);
    }
  }, [authenticationStatus]);
};
