import React, { Suspense, useEffect } from 'react';
import { Navigate, createBrowserRouter, RouterProvider } from 'react-router-dom';
import QrComponent from '@/pages/QrComponent/QrComponent';
import AuthTeachbase from '@/pages/AuthTeachbase/AuthTeachbase';
import PreAccelerationAuth from '@/pages/PreAccelerationAuth/PreAccelerationAuth';
import { Box } from '@material-ui/core';
import AuthPage from '@/pages/AuthPage/AuthPage';
import { ScreenLoader } from '@/shared/ui/ScreenLoader';
import RouterProviderLayout from '@/app/RouterProviderLayout';

const LkPage = React.lazy(() => import('../pages/LkPage/LkPage'));
const AdminPage = React.lazy(() => import('../pages/AdminPage/AdminPage'));
const LandingPage = React.lazy(() => import('../pages/LandingPage/LandingPage'));

const RedirectToPdf = () => {
  useEffect(() => {
    window.location.href = '/winners.pdf';
  }, []);

  return <ScreenLoader fullScreen />;
};

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Suspense fallback={<ScreenLoader fullScreen />}>
        <RouterProviderLayout />
      </Suspense>
    ),
    children: [
      { path: '/', element: <LandingPage /> },
      {
        path: 'auth/*',
        element: (
          <Box display='flex' flex={1} justifyContent='center' overflow='auto'>
            <AuthPage />
          </Box>
        ),
      },
      { path: '/winners', element: <RedirectToPdf /> }, // Редирект на файл winners.pdf
      { path: 'lk/*', element: <LkPage /> },
      { path: 'admin/*', element: <AdminPage /> },
      { path: 'qr', element: <QrComponent /> },
      { path: 'teachbase_auth', element: <AuthTeachbase /> },
      { path: 'preinc_auth', element: <PreAccelerationAuth /> },
      { path: '*', element: <Navigate to='/' replace /> },
    ],
  },
]);

const AppRoutes = () => {
  return <RouterProvider router={router} fallbackElement={<ScreenLoader fullScreen />} />;
};

export default AppRoutes;
