import { baseApi } from '_services/baseApi';
import {
  ChangePasswordMutationPayload,
  EditContactsSettingsMutationPayload,
  EditEducationSettingsMutationPayload,
  EditProfileSettingsMutationPayload,
  GetAdditionalDataResponse,
  ProfileContacts,
  ProfileContactsServerResponse,
} from './types';
import { FormDataBuilder } from '../../models/FormDataBuilder';
import { RequestResult } from '../../_types/RequestResult';
import { UserProfile } from '../../_types/UserProfile';
import { ExpertProfile } from '../../_types/ExpertProfile';

export const usersApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    profile: build.query<UserProfile, void>({
      query: () => '/users/me',
      transformResponse: (responseData: any, meta: any, ha: any) => {
        return {
          ...responseData,
          currentDate: meta?.response?.headers.get('Date'),
        };
      },
      providesTags: ['Profile'],
    }),
    expertProfile: build.query<ExpertProfile, void>({
      query: () => '/users/me',
    }),
    subscribeOfficialTelegramBot: build.mutation<{ link: string }, void>({
      query: () => ({
        url: '/users/tlgr_bot_link',
        method: 'GET',
      }),
    }),
    unsubscribeOfficialTelegramBot: build.mutation<any, void>({
      query: () => ({
        url: '/users/clear_tlgr_bot_link',
        method: 'POST',
      }),
    }),
    acceptParticipationInvite: build.mutation({
      query: () => ({
        url: '/users/accept_invitation',
        method: 'POST',
      }),
    }),
    logActivity: build.mutation<any, void>({
      query: () => ({
        url: '/users/active_log',
        method: 'POST',
      }),
    }),
    changeUserDirection: build.mutation<any, { directionId: number }>({
      query: ({ directionId }) => ({
        url: `/users/${directionId}/choose_direction`,
        method: 'PUT',
      }),
    }),
    changePassword: build.mutation<any, ChangePasswordMutationPayload>({
      query: (params) => ({
        url: '/users/change-password',
        method: 'POST',
        params,
      }),
    }),
    editProfileSettings: build.mutation<any, EditProfileSettingsMutationPayload>({
      query: (body) => ({
        url: '/user/settings/personal_data',
        method: 'POST',
        body: FormDataBuilder.build('user', body),
      }),
      invalidatesTags: ['Profile'],
    }),
    editEducationSettings: build.mutation<any, EditEducationSettingsMutationPayload>({
      query: (body) => ({
        url: '/user/settings/education_institution',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Profile'],
    }),
    editContactsSettings: build.mutation<any, EditContactsSettingsMutationPayload>({
      query: (body) => ({
        url: '/user/settings/contacts',
        method: 'POST',
        body: FormDataBuilder.build('user', body),
      }),
    }),

    editAdditionalData: build.mutation<
      RequestResult,
      { locality: string; isLimitedHealth: number }
    >({
      query: (body) => ({
        url: '/user/settings/additional_data',
        method: 'POST',
        body: FormDataBuilder.build('user', body),
      }),
    }),
    getAdditionalData: build.query<GetAdditionalDataResponse, void>({
      query: () => '/user/settings/additional_data',
    }),
    addRosMolId: build.mutation<RequestResult, string | number>({
      query: (id) => {
        const formData = FormDataBuilder.create({ id }, 'app');
        return {
          url: '/users/myrosmol',
          body: formData,
          method: 'POST',
        };
      },
      invalidatesTags: ['Profile'],
    }),
    getContacts: build.query<ProfileContacts, void>({
      query: () => '/user/settings/contacts',
      transformResponse: (response: ProfileContactsServerResponse) => {
        return {
          myrosmol: response.myrosmol.id,
          phone: response.phone,
          vk_link: response.socialLinks?.[0]?.vk,
          telegram_link: response.socialLinks?.[0]?.telegram,
        };
      },
    }),
  }),
});

export const {
  // Получить информацию по пользователю
  useProfileQuery,
  useExpertProfileQuery,
  useSubscribeOfficialTelegramBotMutation,
  useUnsubscribeOfficialTelegramBotMutation,
  // Принять приглашение в команду
  useAcceptParticipationInviteMutation,
  /** Логировать время активности пользователя */
  useLogActivityMutation,
  useChangeUserDirectionMutation,
  useChangePasswordMutation,
  useEditProfileSettingsMutation,
  useEditEducationSettingsMutation,
  useEditContactsSettingsMutation,
  useEditAdditionalDataMutation,
  useGetAdditionalDataQuery,
  useAddRosMolIdMutation,
  useGetContactsQuery,
} = usersApi;
